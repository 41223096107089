import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import Header from "./Components/header";
import useAutenticacao from "./Hooks/autenticacao";

import Autenticacao from "./Pages/autenticacao";
import CadCliente from "./Pages/cadCliente";
import CadClienteLista from "./Pages/cadCliente/lista";
import CadEntrega from "./Pages/cadEntrega/cadastro";
import CadEntregaLista from "./Pages/cadEntrega/lista";
import CadUsuario from "./Pages/Empresa/EmpresaUsuario/cadastro";
import { CtxParams } from "./Contexts/ctxParams";
import CadTaxa from "./Pages/cadTaxa";
import CadTaxaLista from "./Pages/cadTaxa/lista";
import CadUsuarioLista from "./Pages/Empresa/EmpresaUsuario/lista";
import CadEntregaVisualizacao from "./Pages/cadEntrega/visualizacao";
import CadPagtoQr from "./Pages/cadPagtoQr";
import CadPagtoQrLista from "./Pages/cadPagtoQr/lista";
import { useQuery } from "react-query";
import useAPI from "./Hooks/utils/useAPI";
import { CtxAppParams } from "./Contexts/ctxAppParams";
import CadEmpresa from "./Pages/Empresa/cadastro";
import Empresa from "./Pages/Empresa";
import CadEmpresaIntegracaoLista from "./Pages/Empresa/EmpresaIntegracao/lista";
import EmpresaIntegracao from "./Pages/Empresa/EmpresaIntegracao";
import CadEmpresaIntegracao from "./Pages/Empresa/EmpresaIntegracao/cadastro";
import EmpresaUsuario from "./Pages/Empresa/EmpresaUsuario";
import Suporte from "./Pages/Suporte";
import Cardapio from "./Pages/Cardapio";
import CardapioLista from "./Pages/Cardapio/lista";
import CardapioCadastro from "./Pages/Cardapio/cadastro";
import CardapioProduto from "./Pages/Produto/cadastro";
import Produto from "./Pages/Produto";
import ProdutoLista from "./Pages/Produto/lista";
import CardapioConfiguracao from "./Pages/Cardapio/configuracao";
import Pedido from "./Pages/Pedido";
import PedidoLista from "./Pages/Pedido/lista";
import Mesa from "./Pages/Mesa";
import MesaLista from "./Pages/Mesa/lista";
import MesaCadastro from "./Pages/Mesa/cadastro";
import Fechamento from "./Pages/Fechamento";
import FechamentoLista from "./Pages/Fechamento/lista";
import { IFechamento } from "./Models/Fechamento";
import FormaPagamento from "./Pages/FormaPagamento";
import FormaPagamentoLista from "./Pages/FormaPagamento/lista";
import FormaPagamentoCadastro from "./Pages/FormaPagamento/cadastro";
import Entrega from "./Pages/cadEntrega";
import { IPrinterParams } from "./Models/ctxParams";
import EmpresaParametro from "./Pages/Empresa/EmpresaParametro";
import EmpresaParametroLista from "./Pages/Empresa/EmpresaParametro/lista";
import EmpresaParametroCadastro from "./Pages/Empresa/EmpresaParametro/cadastro";

export interface IPagina {
    Id          : number;
    Alias       : string;
    Description : string;
    Path        : string;
    Element     : JSX.Element;
    Index?      : JSX.Element;
    Pages       : IPaginas;
};
export interface IPaginas extends Array<IPagina>{};

export const Paginas : IPaginas = [
    {
        Id      : 0,
        Alias   : 'FormaPagamento',
        Description: 'Formas de Pagamento',
        Path    : '/formaPagamento',
        Element : <FormaPagamento />,
        Index   : <FormaPagamentoLista />,
        Pages   : [
            {
                Id      : 0,
                Alias   : 'FormaPagamentoCadastro',
                Description: 'Cadastro de Formas de Pagamento',
                Path    : 'cadastro/:cdForma?',
                Element : <FormaPagamentoCadastro />,
                Pages   : []
            }
        ]
    }
];

const Rotas = () => {
    const {token, setToken} = useAutenticacao();
    const AppParams = useContext(CtxAppParams);
    const API = useAPI();

    const [entregador, setEntregador] = useState<boolean>(true);
    const [taxaEntregador, setTaxaEntregador] = useState<number>(0);
    const [taxaEntrega, setTaxaEntrega] = useState<number>(0);
    const [recebeTaxa, setRecebeTaxa] = useState<boolean>(true);
    const [empresa, setEmpresa] = useState<number | null>(null);
    const [dtCompetencia, setDtCompetencia] = useState<string | null>("");
    const [uf, setUf] = useState<string>("");
    const [cidade, setCidade] = useState<string>("");
    const [impressora, setImpressora] = useState<IPrinterParams | undefined>(undefined);

    function validarPaginas(paginas: IPaginas, idPais: number[] = [], aliasSet: Set<string> = new Set()) {
        const erros: string[] = [];

        paginas.forEach((pagina, index) => {
        const idHistory = idPais.concat(index);
    
        if (pagina.Id !== index) {
            const mensagemErro = `Objeto de Pagina de Id ${pagina.Id} inválido, o Id esperado seria ${index} (${idHistory.join('.')})`;
            erros.push(`*${pagina.Alias}* (${mensagemErro});`);
        }
    
        if (aliasSet.has(pagina.Alias)) {
            erros.push(`*${pagina.Alias}* (Alias duplicado encontrado (${idHistory.join('.')}));`);
        } else {
            aliasSet.add(pagina.Alias);
        }
    
        if (pagina.Pages.length > 0) {
            const subErros = validarPaginas(pagina.Pages, idHistory, new Set(aliasSet));
            if (subErros.length > 0) {
            erros.push(...subErros);
            }
        }
        });
    
        return erros;
    }

    useEffect(() => { // Valida objeto de paginas
        const erros = validarPaginas(Paginas);

        if (erros.length > 0) {
            throw new Error(`routes.tsx -> Paginas\n${erros.join('\n')}`);
        }
    })

    useEffect(() => { // Alimenta parâmetros do usuário
        if (token) {
            // Carregar paramêtros ao logar
            setEmpresa(JSON.parse(localStorage.getItem('params') ?? '{}').CD_EMPRESA ?? null);
            setEntregador(JSON.parse(localStorage.getItem('params') ?? '{}').ENTREGADOR ?? true);
            setTaxaEntregador(JSON.parse(localStorage.getItem('params') ?? '{}').TAXA ?? 0);
            setTaxaEntrega(JSON.parse(localStorage.getItem('params') ?? '{}').TAXA_ENTREGA ?? 0);
            setRecebeTaxa(JSON.parse(localStorage.getItem('params') ?? '{}').RECEBE_TAXA ?? true);
            setUf(JSON.parse(localStorage.getItem('params') ?? '{}').UF ?? "");
            setCidade(JSON.parse(localStorage.getItem('params') ?? '{}').CIDADE ?? "");
            setImpressora(JSON.parse(localStorage.getItem('impressora')!) ?? undefined);
            AppParams.setLogado(true);
        };
    }, [token, AppParams]);

    useQuery('competencia', // Alimenta data de competência do fechamento em aberto
        (
            () => token 
                ? API.get('fechamento/buscar/', '', false) 
                : {DT_COMPETENCIA: ""}
        ),
        {
            enabled: token ? true : false,
            onSuccess: (ret: IFechamento) => {
                setDtCompetencia(ret.DT_COMPETENCIA!?.toString());
            },
            onError: (ret: any) => {
                setDtCompetencia("0");
            }
        }
    );

    if(!token!) {
        return (
            <BrowserRouter>
                {
                    AppParams.EscondeHeader ?
                        null
                    :
                        <Header />
                }
                <Routes>                    
                    <Route path={'/cardapio/:cdEmpresa/:nrMesa?/:salvaDados?/:garcom?'} element={<Cardapio />} >
                        <Route index element={<CardapioLista />} />
                    </Route>

                    <Route path="*" element={<Autenticacao setToken={setToken} />} />
                </Routes>
            </BrowserRouter>
        )
    };

    return (
        <CtxParams.Provider value={{
            CD_EMPRESA: empresa,
            ENTREGADOR: entregador,
            TAXA: taxaEntregador,
            TAXA_ENTREGA: taxaEntrega,
            RECEBE_TAXA: recebeTaxa,
            DT_COMPETENCIA: dtCompetencia,
            UF: uf,
            CIDADE: cidade,
            IMPRESSORA: impressora,
            setIMPRESSORA: setImpressora
        }}>
            <BrowserRouter>
                {
                    AppParams.EscondeHeader ?
                        null
                    :
                        <Header />
                }
                
                <div onMouseEnter={() => AppParams.BeepAudio?.init()} style={{marginBottom: '9vh'}}>
                    <Routes>
                        <Route path={'/'} element={<Navigate to={'/fechamento'} />} />

                        <Route path={'/suporte'} element={<Suporte />} />

                        <Route path={'/listaTaxa'} element={<CadTaxaLista />} />
                        <Route path={'/cadTaxa/:cdTaxa?'} element={<CadTaxa />} />

                        <Route path={'/listaCliente'} element={<CadClienteLista />} />
                        <Route path={'/cadCliente/:cdCliente?/:fechar?'} element={<CadCliente />} />

                        <Route path={'/listaPagtoQr/:voltar?'} element={<CadPagtoQrLista />} />
                        <Route path={'/cadPagtoQr/:cdPagtoQr?'} element={<CadPagtoQr />} />

                        <Route path={'/entrega/:dtCompetencia'} element={<Entrega />} >
                            <Route index element={<CadEntregaLista />} />
                            <Route path={"cadastro/:cdEntrega?"} element={<CadEntrega />} />
                            <Route path={"verEntrega/:cdEntrega?/:pgOrigem?"} element={<CadEntregaVisualizacao />} />
                        </Route>

                        <Route path={'/fechamento'} element={<Fechamento />} >
                            <Route index element={<FechamentoLista />} />
                        </Route>

                        <Route path={'/empresa'} element={<Empresa />} >
                            <Route index element={<CadEmpresa />} />
                            
                            <Route path={"parametros"} element={<EmpresaParametro />} >
                                <Route index element={<EmpresaParametroLista />} />
                                <Route path="cadastro/:cdParametro" element={<EmpresaParametroCadastro />} />
                            </Route>

                            <Route path={"integracoes"} element={<EmpresaIntegracao />} >
                                <Route index element={<CadEmpresaIntegracaoLista />} />
                                <Route path={"cadastro/:cdEmpresaIntegracao"} element={<CadEmpresaIntegracao />} />
                            </Route>

                            <Route path={"usuarios"} element={<EmpresaUsuario />} >
                                <Route index element={<CadUsuarioLista />} />
                                <Route path={"cadastro/:cdUsuario?"} element={<CadUsuario />} />
                            </Route>
                        </Route>

                        <Route path={'/cardapio/:cdEmpresa/:nrMesa?/:salvaDados?/:garcom?'} element={<Cardapio />} >
                            <Route index element={<CardapioLista />} />
                            
                            <Route path={"cadastro/:cdCategoria/:pTipoCategoria?"} element={<CardapioCadastro />} />
                            <Route path={"configuracao"} element={<CardapioConfiguracao />} />
                        </Route>

                        <Route path={'/produto/:cdEmpresa'} element={<Produto />} >
                            <Route index element={<ProdutoLista />} />
                            <Route path={"cadastro/:cdProduto/:pTipoProduto?"} element={<CardapioProduto />} />
                        </Route>

                        <Route path={'/pedido/:dtCompetencia'} element={<Pedido />} >
                            <Route index element={<PedidoLista />} />
                        </Route>

                        <Route path={'/mesa/:dtCompetencia'} element={<Mesa />} >
                            <Route index element={<MesaLista />} />
                            <Route path={"cadastro/:cdMesa"} element={<MesaCadastro />} />
                        </Route>

                        <Route path={Paginas[0].Path} element={Paginas[0].Element} >
                            <Route index element={Paginas[0].Index} />
                            <Route path={Paginas[0].Pages[0].Path} element={Paginas[0].Pages[0].Element} />
                        </Route>

                    </Routes>
                </div>
            </BrowserRouter>
        </CtxParams.Provider>
    )
}

export default Rotas;