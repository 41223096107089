import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ICliente, IPedido, IPedidoItem, IPedidoItems, IPedidos } from "../../Models/pedido";
import usePedidoController, {IUsePedidoController} from "./controller";
import { DeepPartial, EMode, IReactTable_Grid, IReactTable_InitState } from "../../Models/genericInterfaces";
import moment from 'moment';
import { useLocation, useNavigate } from "react-router-dom";
import { EStatus, IEntrega } from "../../Models/cadEntrega";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { Badge, Col, Row } from "react-bootstrap";
import useMask from "../utils/useMask";
import BadgeStatus from "../../Components/formularios/BadgeStatus";
import { useQueryClient } from "react-query";
import usePedidoUtil from "./util";
import { v4 as uuidv4 } from 'uuid';
import useEntregaController, { IUseEntregaController } from "../cadEntrega/controller";
import { TbCashBanknoteOff } from "react-icons/tb";

const usePedido = (DtCompetencia: string = '') : IUsePedido => {
    const DataValida = moment(DtCompetencia, "YYYY-MM-DD", true).isValid();
    const Controller = usePedidoController('listaPedidos', true, DataValida, DtCompetencia);
    const ControllerEntrega = useEntregaController('entregaListaPedido', true, false, undefined);
    const [Mode, setMode] = useState<EMode>(EMode.Browse);
    const AppParams = useContext(CtxAppParams);
    const navigate = useNavigate();
    const location = useLocation();
    const { setMaskMoney, setDate, getQtdCaixaString } = useMask();
    const DataCompetencia = DtCompetencia;
    const PedidoUtil = usePedidoUtil();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (!DataValida && (location.pathname === `/pedido/${DtCompetencia}`)) {
            navigate('/');
        };
    }, [DataValida, navigate, location.pathname, DtCompetencia])

    const dsLista = useMemo<IPedidos>(() => {
        const RetornouComSucesso = Controller.Listar.isSuccess && Controller.Listar.data && Controller.Listar.data.length > 0
        return (
            RetornouComSucesso ? 
                Controller.Listar.data!.filter((data: IPedido) => {
                    return data
                }).map((data) => {
                    return {
                        ...data, 
                        OFF_REACT_TABLE_PROPS: {
                            alert: data.STATUS === EStatus.Pendente
                        }
                    } as IPedido
                })
            : 
                [{} as IPedido]
        )
    }
    , [Controller.Listar]);

    const dsItem = useMemo<IPedido>(() => {
        if (Controller.Buscar.isSuccess) {
            const itemEncontrado = dsLista.find((item: IPedido) => item.CD_PEDIDO === Controller.Buscar.data?.CD_PEDIDO);

            if (itemEncontrado) {
                return {
                    ...Controller.Buscar.data,
                    STATUS: itemEncontrado.STATUS,
                    DT_FECHAMENTO: itemEncontrado.DT_FECHAMENTO,
                    PAGO:   itemEncontrado.PAGO,
                    ENTREGA: {
                        ...Controller.Buscar.data.ENTREGA as IEntrega,
                        CD_USUARIO: itemEncontrado.ENTREGA?.CD_USUARIO,
                        NOME_USUARIO: itemEncontrado.ENTREGA?.NOME_USUARIO,
                    }
                }
            } else {
                return Controller.Buscar.data
            }
        }

        return {} as IPedido
    }, [Controller.Buscar, dsLista]);

    const tableInitialState : IReactTable_InitState = { pageSize: 15 }

    const gridLista = useMemo<IReactTable_Grid>(() => [  
        {
            id: "INFO",
            Header: "Informações",
            Cell: (row: any) : any => {
                const pedido = row.row.original as IPedido
                return (
                    <Col>
                        <Row xs={12}>
                            <Col xs={10} className="d-flex justify-content-start">
                                <div>{pedido.NR_MESA ? 'Mesa ' + pedido.NR_MESA : pedido.NOME_CLIENTE}</div>
                            </Col>
                            <Col xs={2} className="d-flex justify-content-end">
                                {
                                    pedido.NR_MESA || pedido.STATUS !== EStatus.Finalizada
                                        ? null
                                        : <div>{pedido.PAGO === false 
                                            ? <Badge pill bg="warning" text="dark"><TbCashBanknoteOff style={{zoom: '1.3'}} />Pagamento pendente</Badge>
                                            : null
                                        }</div>
                                }
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={8} className="d-flex justify-content-start">
                                <Badge pill bg="secondary" className="d-flex align-items-center" style={{fontWeight: 'bold'}}>#{pedido.CD_PEDIDO}</Badge>
                                <Badge pill bg="light" text="success" className="d-flex align-items-center">{setMaskMoney(pedido.TOTAL ?? 0, 'R$', ',')}</Badge> 
                                {pedido.FORMA_PAGTO ? <Badge pill bg="light" text="primary" className="d-flex align-items-center">{pedido.FORMA_PAGTO}</Badge> : null}
                            </Col>
                            <Col xs={4} className="d-flex justify-content-end">
                                {
                                    pedido.ENTREGA && pedido.ENTREGA.CD_ENTREGA !== undefined && pedido.STATUS === EStatus.Finalizada
                                    ? <BadgeStatus icone={true} tipo="entrega" status={pedido.ENTREGA.STATUS} />
                                    : <BadgeStatus icone={true} tipo="pedido" status={pedido.STATUS} />
                                }
                            </Col>
                        </Row>
                    </Col>
                )
            }
        }
    ], [setMaskMoney]);

    const gridItensPedido = useMemo<IReactTable_Grid>(() => [  
        {
            id: "QTD",
            Header: "Qtd",
            Cell: (row: any) : any => {
                const item = row.row.original as IPedidoItem

                if ((Number(item.CAIXA_QTD ?? 1) > 1 && item.CAIXA_NOME) && (item.QTD_PRODUTO >= Number(item.CAIXA_QTD ?? 1))) {
                    return <Col>
                        <Row>
                            <div style={{fontWeight: 'bold'}}>{getQtdCaixaString(item.QTD_PRODUTO, item.CAIXA_QTD, item.CAIXA_NOME) }</div>
                        </Row>
                        <Row>
                            <div style={{fontWeight: 'lighter'}}>{item.QTD_PRODUTO} un</div>
                        </Row>
                    </Col>
                } else {
                    return <div style={{fontWeight: 'bold'}}>{item.QTD_PRODUTO}</div>
                }                
            }
        },
        {
            id: "ITEMS",
            Header: "Itens",
            Cell: (row: any) : any => {
                const item = row.row.original as IPedidoItem
                const complementos = PedidoUtil.agruparComplementosPorComposição([item]);
                return (
                    <Col>
                        <Row>
                            <div key={'a'+uuidv4()} style={{fontWeight: 'bold'}}>
                                {
                                    item.VARIACAO 
                                        ? item.NOME + ' ' + item.VARIACAO.NOME
                                        : item.NOME
                                }
                            </div>
                            <div key={'e'+uuidv4()}>{item.OBS_PRODUTO}</div>
                        </Row>
                        <Row>
                            {
                                complementos.length > 0 ?
                                    complementos.map((complemento) => {
                                        return (
                                            <>
                                                <div key={'b'+uuidv4()}><br />{complemento.COMPLEMENTO+':'}</div>
                                                <div key={'c'+uuidv4()}>
                                                    {
                                                        complemento.ITENS.map((itens: IPedidoItem) => {
                                                            return (
                                                                <div key={'d'+uuidv4()}>{`- (${itens.QTD_PRODUTO}x) ${itens.NOME} ${itens.COMPOSICAO ? '' : setMaskMoney(itens.VLRUN_PRODUTO, 'R$', ',')}`}</div>
                                                            )
                                                        })
                                                    }
                                                </div>    
                                            </>
                                        )
                                    })
                                :
                                    null
                            }
                        </Row>
                    </Col>
                )
            }
        },
        {
            id: "VALOR",
            Header: "Valor(R$)",
            Cell: (row: any) : any => {
                const item = row.row.original as IPedidoItem
                return <div style={{fontWeight: 'bold'}}>{setMaskMoney((item.VLRUN_PRODUTO * item.QTD_PRODUTO), 'R$', ',')}</div>
            }
        }
    ], [setMaskMoney, PedidoUtil, getQtdCaixaString]);

    useEffect(() => {
        if ((DtCompetencia !== '') && (dsLista.some((item) => item.OFF_REACT_TABLE_PROPS?.alert === true))) {
            AppParams.BeepAudio?.play();
        } else {
            AppParams.BeepAudio?.pause();
        }

        return () => {
            AppParams.BeepAudio?.pause();
        }
    }, [dsLista, AppParams, DtCompetencia])

    const PreparaPedido = useCallback((Pedido: IPedido, Items: IPedidoItems, Cliente: ICliente, Entrega: IEntrega) : IPedido => {
        const vItems : IPedidoItems = [...Items];
        const okItems = vItems.map((x) => {
            const newComplementos = x.COMPLEMENTOS!.map((y) => {
                const { CD_COMPLEMENTO, ...rest } = y;
                return rest;
            });
            
            return { ...x, COMPLEMENTOS: newComplementos };
        });

        const vPedido : IPedido = {
            ...Pedido, 
            VLR_TROCO: Pedido.VLR_TROCO ? Number(Pedido.VLR_TROCO) - Number(Pedido.VLR_TOTAL)  : 0,
            CLIENTE: Cliente, 
            ITENS: okItems, 
            ENTREGA: JSON.stringify(Entrega.ENDERECO) === '{}' ? undefined : {
                ...Entrega,
                DT_ENTREGA: setDate(new Date(), { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" }, false, true),
                CLIENTE: Cliente,
                PAGO: false,
                STATUS: EStatus.Pendente,
                FORMA_PAGTO: Pedido.FORMA_PAGTO ?? "A Vista",
                VLR_TROCO: Pedido.VLR_TROCO ? Number(Pedido.VLR_TROCO) - Number(Pedido.VLR_TOTAL)  : 0
            }
        };
        delete vPedido.OFF_TOTAL;

        return vPedido;
    }, [setDate]);

    const Salvar = useCallback(async (Pedido: IPedido, Items: IPedidoItems, Cliente: ICliente, Entrega: IEntrega) => {
        return await new Promise<{success: boolean, result: string}> ((resolve, reject) => {
            const Objeto : IPedido = PreparaPedido(Pedido, Items, Cliente, Entrega);

            if (Mode === EMode.Edit) {
                Controller.Alterar.mutateAsync(Objeto, {
                    onSuccess: () => {
                        resolve({success: true, result: ''})
                    },
                    onError : (e) => {
                        reject(e)
                    }
                });
            } else if (Mode === EMode.Include) {
                Controller.Cadastrar.mutateAsync(Objeto, {
                    onSuccess: (ret: any) => {
                        resolve({success: true, result: ret['Sucesso']['CD_PEDIDO']})
                    },
                    onError : (e) => {
                        reject(e)
                    }
                });
            };
        })
    }, [Controller.Cadastrar, Controller.Alterar, Mode, PreparaPedido]);

    const Alterar = useCallback((Pedido: DeepPartial<IPedido>) => {
        Controller.Alterar.mutate(Pedido);
    }, [Controller.Alterar]);

    const Buscar = useCallback((Pedido: DeepPartial<IPedido>) => {
        if (dsItem.CD_PEDIDO !== Pedido.CD_PEDIDO) {
            Controller.Buscar.mutate(Pedido);
        }
    }, [Controller.Buscar, dsItem.CD_PEDIDO])

    useEffect(() => {
        if (Controller.Listar.isFetchedAfterMount && Controller.Listar.isSuccess && Controller.Buscar.isIdle && dsLista[0].CD_PEDIDO) {
            Buscar({CD_PEDIDO: dsLista[0].CD_PEDIDO})
        }
    }, [Controller.Listar, Controller.Buscar, Buscar, dsLista]);

    const atualizarEntregaItem = (entrega: DeepPartial<IEntrega>) => {
        queryClient.setQueryData(
            'listaPedidos',
            (oldData: any) => {
                if ((oldData)) {
                    return oldData.map((oldPedido: IPedido) => {
                        if (oldPedido.ENTREGA && oldPedido.ENTREGA.CD_ENTREGA === entrega.CD_ENTREGA) {
                            oldPedido.ENTREGA = {
                                ...oldPedido.ENTREGA,
                                CD_USUARIO: entrega.CD_USUARIO,
                                NOME_USUARIO: entrega.NOME_USUARIO                                
                            }
                        }
                        return oldPedido;
                    });
                }
                return oldData;
            }
        )
    }

    const verEntrega = (entrega: DeepPartial<IEntrega>) => {
        navigate(`/entrega/${DtCompetencia}/verEntrega/${entrega.CD_ENTREGA}`);
    };

    return {
        Modo: Mode,
        setModo: setMode,
        Salvar: Salvar,
        Alterar: Alterar,
        Buscar: Buscar,
        Item: dsItem,
        Lista: dsLista,
        Grid: gridLista,
        GridItens: gridItensPedido,
        EstadoInicial: tableInitialState,
        // Impressao: impressoraUsb,
        Controller: Controller,
        DataCompetencia: DataCompetencia,
        AtualizarEntregaItem: atualizarEntregaItem,
        VisualizarEntrega: verEntrega,
        ControllerEntrega: ControllerEntrega
    }
}

export interface IUsePedido {
    Modo                    : EMode;
    setModo                 : React.Dispatch<React.SetStateAction<EMode>>;
    Salvar                  : (Pedido: IPedido, Items: IPedidoItems, Cliente: ICliente, Entrega: IEntrega) => Promise<{success: boolean, result: string}>;
    Alterar                 : (Pedido: DeepPartial<IPedido>) => void;
    Buscar                  : (Pedido: DeepPartial<IPedido>) => void;
    Item                    : IPedido;
    Lista                   : IPedidos;
    Grid                    : IReactTable_Grid;
    GridItens               : IReactTable_Grid;
    EstadoInicial           : IReactTable_InitState;
    // Impressao               : IImpressoraUSB;
    Controller              : IUsePedidoController;
    DataCompetencia         : string;
    AtualizarEntregaItem    : (entrega: DeepPartial<IEntrega>) => void;
    VisualizarEntrega       : (entrega: DeepPartial<IEntrega>) => void;
    ControllerEntrega       : IUseEntregaController;
}

export default usePedido;