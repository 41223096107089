import { useCallback, useContext } from "react";
import { CtxParams } from "../Contexts/ctxParams";
import useApp from "../Hooks/utils/useApp";
import { IPrinterParams } from "../Models/ctxParams";
import { ICtxAppParams_ModalInputs } from "../Models/ctxAppParams";
import ImpressoraESCPOS from "./esc-pos";
import { IPedido } from "../Models/pedido";
import ReportPedido from "./Pedido";
import { IEntrega } from "../Models/cadEntrega";

const useReports = () => {
    const UserParams = useContext(CtxParams);
    const escpos = ImpressoraESCPOS();
    const { Modal } = useApp();

    const resetarConfiguracao = () => {
        UserParams.setIMPRESSORA!(undefined);
        localStorage.removeItem('impressora');
    };

    const print = useCallback((Relatorio: 'PEDIDO' | 'ENTREGA', Dados: any) => {
        const imprimir = (configuracao: IPrinterParams) => {
            switch(Relatorio){
                case 'PEDIDO':
                    if (configuracao.TIPO === "ESC-POS") {
                        escpos.Imprimir(Dados as IPedido, Relatorio, process.env.REACT_APP_AMBIENTE === 'P');
                    } else if (configuracao.TIPO === "NORMAL") {
                        ReportPedido(Dados as IPedido);
                    }
                break;
                case 'ENTREGA':
                    if (configuracao.TIPO === "ESC-POS") {
                        escpos.Imprimir(Dados as IEntrega, Relatorio, process.env.REACT_APP_AMBIENTE === 'P');
                    } else if (configuracao.TIPO === "NORMAL") {
                        // ReportPedido(Dados as IEntrega);
                    };
                break;
            };
        }

        if (!UserParams.IMPRESSORA) {
            Modal({
                Visibilidade    : true,
                Titulo          : 'Configuração de Impressora',
                Descricao       : `É necessário infomar qual impressora deseja usar:`,
                Anexos			: undefined,
                Inputs          : [
                    {
                        ID: 'fldTpImpressora',
                        Label: 'Tipo de Impressora: ',
                        Placeholder: '',
                        Type: 'select',
                        Value: 'ESC-POS',
                        Options: [
                            {
                                text: 'Impressão Direta (Térmica / ESC-POS)',
                                value: 'ESC-POS'
                            },
                            {
                                text: 'Normal',
                                value: 'NORMAL'
                            }
                        ]
                    },
                    {
                        ID: 'fldTpConexao',
                        Label: 'Conexão: ',
                        Placeholder: '',
                        Type: 'select',
                        Value: 'WebUSB',
                        Options: [
                            {
                                text: 'USB / Rede',
                                value: 'WebUSB'
                            },
                            {
                                text: 'Bluetooth',
                                value: 'WebBluetooth'
                            }
                        ]
                    }
                ],
                Variante        : 'primary',
                onSubmit        : (fields: ICtxAppParams_ModalInputs) => {
                    const config : IPrinterParams = {
                        TIPO: String(fields[0].Value) as 'ESC-POS' | 'NORMAL', 
                        CONEXAO: String(fields[1].Value) as 'WebUSB' | 'WebBluetooth'
                    }
                    
                    localStorage.setItem('impressora', JSON.stringify(config))
                    UserParams.setIMPRESSORA!(config);
                    imprimir(config);
                }
            })
        } else {
            imprimir(UserParams.IMPRESSORA);
        }
    }, [Modal, UserParams.IMPRESSORA, UserParams.setIMPRESSORA, escpos]);

    return {
        Print: print,
        Reset: resetarConfiguracao
    }
}

export interface IUseReports {
    Print : (Relatorio: 'PEDIDO' | 'ENTREGA', Dados: any) => void;
    Reset : () => void;
}

export default useReports;