import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { ICardapio, ICardapios, IProduto } from '../../Models/cardapio';
import { Button, Card } from 'react-bootstrap';
import useMask from '../../Hooks/utils/useMask';
import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { ContextMenu } from '../misc/ContextMenu/ContextMenu';
import { FaCheck, FaPencilAlt, FaTimes, FaTrashAlt } from 'react-icons/fa';
import { DeepPartial } from '../../Models/genericInterfaces';
import { UseMutationResult } from 'react-query';
import ReactHammer from 'react-hammerjs';
import useApp from '../../Hooks/utils/useApp';

interface Props {
    Dados   : ICardapios;
    logado  : boolean;
    onClickItem : (Produto: DeepPartial<IProduto>) => void;
    onClickCategoria : (Cardapio: DeepPartial<ICardapio>) => void;
    onUpdateItem : UseMutationResult<any, any, DeepPartial<IProduto>, unknown>;
    onDeleteItem : UseMutationResult<any, any, DeepPartial<IProduto>, unknown>;
    onAddPedidoItem : () => void;//React.Dispatch<React.SetStateAction<IPedido>>;
};

const CardapioList = ({Dados, logado, onClickItem, onClickCategoria, onUpdateItem, onDeleteItem, onAddPedidoItem} : Props) => {
    const formatter = useMask();
    const [anchor, setAnchor] = useState<string | undefined>();
    const [contexto, setContexto] = useState<IProduto | ICardapio | undefined>();
    const [contextoComplementar, setContextoComplementar] = useState<ICardapio | undefined>(); //eslint-disable-line
    const [rowSelected, setRowSelected] = useState<string | null>(null);
    const [pressPos, setPressPos] = useState<any>({x: 0, y: 0});
    const ref = useRef<any>();    
    const App = useApp();

    useEffect(() => {
        document.getElementById('listaCardapio')?.focus();
        ref.current = document.querySelector("#content");
        const childs = ref.current?.getElementsByClassName('sections');
        
        ref.current?.addEventListener("scroll", () => {
            for (let i = 0; i < childs.length; i++) {
                const rect = childs[i].getBoundingClientRect();

                if (rect.top >= 100) {
                    setAnchor(childs[i].id);
                    document.getElementById(String(childs[i].id).replace('section', ''))?.scrollIntoView();

                    break;
                };
            }
        });
    }, []);

    const handleContextPress = useCallback((pos: any) => {
        document.dispatchEvent(
            new MouseEvent('contextmenu', {
                view: window,
                bubbles: true, 
                cancelable: true, 
                clientX: pos.x, 
                clientY: pos.y
            })
        )
    },  []);

    useEffect(() => {
        handleContextPress(pressPos);
    }, [handleContextPress, pressPos]);

    useEffect(() => {
        document.addEventListener('mouseup', () => {setContexto(undefined); setRowSelected(null)});

        return () => {
            document.removeEventListener('mouseup', () => {setContexto(undefined); setRowSelected(null)});
        };
    });

    const excluirProduto = (ctx: IProduto) => {
        onDeleteItem.mutate({
            CD_PRODUTO: (ctx as IProduto).CD_PRODUTO,
            OtherQuery: {
                QueryKey: 'listaCardapio', 
                Params: Number((ctx as IProduto).CD_PRODUTO)
        }})
    }

    return (
        <Tab.Container id="listaCardapio" defaultActiveKey={`section${Dados[0].CD_CATEGORIA}`} activeKey={anchor}>            
            {
                logado ?
                    <ContextMenu
                        context={contexto}
                        header={(ctx: ICardapio) => ctx.NOME}
                        items={(ctx: ICardapio) => [
                            {
                                name: 'editar',
                                icon: <FaPencilAlt />,
                                caption: ctx.PRODUTOS 
                                    ? 'Editar Categoria' 
                                    : 'Editar',
                                action: () => ctx.PRODUTOS 
                                    ? onClickCategoria({CD_CATEGORIA: ctx.CD_CATEGORIA})
                                    : onClickItem({CD_PRODUTO: (ctx as unknown as IProduto)?.CD_PRODUTO})
                            },
                            ctx.PRODUTOS
                                ? undefined
                                : {
                                name: 'excluir',
                                icon: <FaTrashAlt />,
                                caption: 'Excluir',
                                action: () => App.Modal({
                                    Visibilidade    : true,
                                    Titulo          : 'Confirmação de Exclusão',
                                    Descricao       : `Tem certeza que deseja excluir o produto?`,
                                    Anexos			: <></>,
                                    Inputs          : [],
                                    Variante        : 'danger',
                                    onSubmit        : () => excluirProduto(ctx as unknown as IProduto)
                                })
                            },
                            ctx.PRODUTOS
                                ? undefined
                                : {
                                    name: 'disponibilidade',
                                    icon: (ctx as unknown as IProduto)?.DISPONIVEL ? <FaTimes style={{zoom: 1.1}} /> : <FaCheck />,
                                    caption: (ctx as unknown as IProduto)?.DISPONIVEL ? 'Marcar como Indisponível' : 'Marcar como Disponível',
                                    action: () => (ctx as unknown as IProduto)?.DISPONIVEL 
                                        ? onUpdateItem.mutate({
                                            CD_PRODUTO: (ctx as unknown as IProduto).CD_PRODUTO, 
                                            DISPONIVEL: false,
                                            OtherQuery: {
                                                QueryKey: 'listaCardapio', 
                                                Params: Number((ctx as unknown as IProduto).CD_PRODUTO)
                                            }
                                        }) 
                                        : onUpdateItem.mutate({
                                            CD_PRODUTO: (ctx as unknown as IProduto).CD_PRODUTO,
                                            DISPONIVEL: true,
                                            OtherQuery: {
                                                QueryKey: 'listaCardapio', 
                                                Params: Number((ctx as unknown as IProduto).CD_PRODUTO)
                                            }
                                        })
                                },                    
                        ]}
                    />
                : null
            }
            
            
            <Row 
                className="w-100" 
                onMouseLeave={() => {
                    if(!!contexto) {
                        setContexto(undefined); 
                        // setContextoComplementar(undefined);
                    };
                }}
                >
                <Col sm={3}>
                    <Nav variant="pills" id='navCategorias' className={styles.categorias}>
                        {
                            Dados.map((Dado: ICardapio) => 
                                <Nav.Item key={String(Dado.CD_CATEGORIA)} onContextMenu={() => setContexto(Dado)} id={String(Dado.CD_CATEGORIA)}>
                                    <Nav.Link eventKey={`section${Dado.CD_CATEGORIA}`} href={`#section${Dado.CD_CATEGORIA}`} style={Dado.ATIVO ? {} : {textDecoration: 'line-through'}}>{Dado.NOME}</Nav.Link>
                                </Nav.Item>
                            )
                        }                        
                    </Nav>
                </Col>
                <Col sm={9}>
                    <Tab.Content>
                        <div id='content' className={styles.conteudo}>
                        {
                            Dados.map((Dado: ICardapio, index: number) => 
                                <div key={`sec${Dado.CD_CATEGORIA}`} id={`section${Dado.CD_CATEGORIA}`} className='sections'>
                                    {index === 0 ? null : <br />}
                                    <h5 
                                        className='w-100 pe-3 d-flex justify-content-between'
                                        style={Dado.ATIVO ? {} : {textDecoration: 'line-through'}}
                                    >
                                        {Dado.ATIVO ? '' : 'INATIVO - '}
                                        {Dado.NOME} 
                                        <Button size='sm' style={logado ? {} : {display: 'none'}} variant='warning' onClick={() => onClickCategoria({CD_CATEGORIA: Dado.CD_CATEGORIA})}><FaPencilAlt /> Editar</Button>
                                    </h5>
                                    {                                        
                                        Dado.PRODUTOS !== undefined ?
                                            Dado.PRODUTOS.map((Produto: IProduto, indexProduto: number) => {
                                                const lastItem = (indexProduto === (Dado.PRODUTOS!.length - 1)) && (index === (Dados.length - 1)) ? {marginBottom: '20%'} : {marginBottom: '1rem'}
                                                const selection = String(index)+String(indexProduto) === rowSelected ? {boxShadow: '0.2rem 0.2rem 1rem 0.2rem rgba(0,0,0,0.21)'} : {};
                                                const disabled = Produto.DISPONIVEL && Dado.ATIVO
                                                    ? {
                                                        body: {
                                                            cursor: 'pointer',
                                                        }
                                                    } 
                                                    : {
                                                        card: {
                                                            backgroundColor: '#F8F8F8',
                                                            color: '#6E6E6E',
                                                        },
                                                        body: {
                                                            cursor: 'not-allowed',
                                                        },
                                                        image: {
                                                            filter: 'grayscale(90%)'
                                                        }
                                                    }
                                                return (
                                                    <ReactHammer 
                                                        key={'rh'+Produto.CD_PRODUTO}
                                                        options={{touchAction: 'auto'}}
                                                        onPress={(e) => {
                                                            setContexto(Produto);
                                                            setRowSelected(String(index)+String(indexProduto));
                                                            setPressPos({x: e.center.x, y: e.center.y});
                                                        }}
                                                    >
                                                        <Card 
                                                            key={Produto.CD_PRODUTO} 
                                                            onContextMenu={() => {
                                                                setContexto(Produto); 
                                                                setContextoComplementar(Dado);
                                                                setRowSelected(String(index)+String(indexProduto))
                                                            }} 
                                                            onClick={() => 
                                                                onClickItem({...Produto, CD_CATEGORIA: Number(Dado.CD_CATEGORIA)})
                                                            } 
                                                            style={{
                                                                height: '8rem', 
                                                                flexDirection: 'row', 
                                                                ...disabled.card,
                                                                ...selection,
                                                                ...lastItem
                                                            }} 
                                                            // className='mb-3'
                                                        >                                                    
                                                            {
                                                                Produto.CAMINHO_IMAGEM
                                                                ? <Card.Img variant="left" loading='lazy' style={{width: '8rem', height: 'auto', objectFit: 'cover', ...disabled.image}} src={Produto.CAMINHO_IMAGEM} />
                                                                : null
                                                            }                                                            
                                                            <Card.Body style={{ justifyContent: 'space-around', ...disabled.body }}>
                                                                <Card.Title>{Produto.NOME}</Card.Title>
                                                                <div className='mb-0' style={{display: 'table', tableLayout: 'fixed', height: '2.2rem', width: '100%'}}>
                                                                    <Card.Text style={{display: 'table-cell', whiteSpace: 'nowrap', wordWrap: 'break-word', textOverflow: 'ellipsis', overflow: 'hidden', overflowWrap: 'break-word'}}>
                                                                        {
                                                                            Produto.INGREDIENTES !== undefined && Produto.INGREDIENTES.length > 0 ? (
                                                                                Produto.INGREDIENTES.map((ingrediente, index) => (
                                                                                    index === Produto.INGREDIENTES.length - 1 ? (
                                                                                        String(Number(ingrediente.QUANTIDADE) > 1 ? String(ingrediente.QUANTIDADE) + 'x ' : '') + ingrediente.INGREDIENTE
                                                                                    ) : (
                                                                                        `${String(Number(ingrediente.QUANTIDADE) > 1 ? String(ingrediente.QUANTIDADE) + 'x ' : '') + ingrediente.INGREDIENTE}, `
                                                                                    )
                                                                                )).join('')
                                                                            ) : (
                                                                                Produto.OBSERVACAO ?? ''
                                                                            )
                                                                        }
                                                                    </Card.Text>
                                                                </div>
                                                                <Card.Title style={Produto.DISPONIVEL ? {color: '#198754'} : {color: '#6E6E6E'}}>{
                                                                    Produto.VARIACAO && Produto.VARIACAO.length > 0 ? `A partir de ${formatter.setMaskMoney(Produto.VARIACAO.sort((a, b) => a.VALOR - b.VALOR)[0].VALOR, 'R$', ',')}` : formatter.setMaskMoney(Produto.VALOR, 'R$', ',')
                                                                }</Card.Title>
                                                            </Card.Body>
                                                        </Card>
                                                    </ReactHammer>
                                                )
                                            })
                                        :
                                            null
                                    }
                                </div>
                            )
                        }  
                        </div>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    );
}

export default CardapioList;