import { useContext, useEffect } from "react";
import { Button, Card, Container } from "react-bootstrap";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import { FaChromecast, FaWhatsapp } from "react-icons/fa";

const Suporte = () => {
    const AppParams = useContext(CtxAppParams);

    useEffect(() => {
        AppParams.setPagina("Suporte");
    });

    return (
        <Container className="verticalCenter d-flex justify-content-around align-items-center flex-column">
            <Card 
                key={'contato'} 
                className="text-center m-3"
                style={{minWidth: '600px'}}
            >
                <Card.Header 
                    as={"h5"}
                    className={"d-flex justify-content-around align-items-center"}
                >
                    Contato
                </Card.Header>
                <Card.Body>
                    Dúvidas ou problemas com o Frota? <br />
                    Entre em contato com nosso setor de suporte via Whatsapp.
                    <br /> <br />
                    - Tempo limite de resolução de incidentes internos: 24 Horas
                    <br />
                    - Triagem do atendimento somente via Whatsapp
                    <br /> <br />
                    <Button 
                        variant="success"
                        onClick={(e: any) => {
                            window.open(`https://api.whatsapp.com/send?phone=5527996957014`);
                        }}
                    >
                        <FaWhatsapp /> Iniciar Conversa
                    </Button>
                </Card.Body>
            </Card>

            <Card 
                key={'remoto'} 
                className="text-center m3"
                style={{minWidth: '600px'}}
            >
                <Card.Header 
                    as={"h5"}
                    className={"d-flex justify-content-around align-items-center"}
                >
                    Acesso Remoto
                </Card.Header>
                <Card.Body>
                    Você precisa de acesso remoto? <br />
                    <br /> <br />
                    - Disponível somente em Computadores
                    <br />
                    - Apenas Google Chrome
                    <br /> <br />
                    <Button 
                        variant="primary"
                        onClick={(e: any) => {
                            window.open(`https://remotedesktop.google.com/support`);
                        }}
                    >
                        <FaChromecast /> Conexão Remota
                    </Button>
                </Card.Body>
            </Card>
        </Container>
    )
}

export default Suporte;