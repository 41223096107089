import usePedidoUtil from "../../Hooks/Pedido/util";
import useMask from "../../Hooks/utils/useMask";
import { IPedido, IPedidoItem } from "../../Models/pedido";

const ImpressaoPedido = (Pedido: IPedido, Colunas: number) => {
    const { setMaskMoney, getEnderecoString, setDate } = useMask();
    const pedidoUtil = usePedidoUtil();
    const _margem = 10;
    const SIZE = (TamCampo: number) : number => {
        return Math.trunc((TamCampo * (Colunas - _margem)) / 100)
    }
    const BREAK_ITEM = (Texto: string, TamMax: number = 70) : string => {
        const maxsize = SIZE(TamMax);
        let text = "";
    
        if (Texto && Texto !== "") {
            for (let i = 0; i < Texto.length; i++) {
                if (i > 0 && i % maxsize === 0) {
                    text += `\n│ ${' '.padStart(SIZE(10), ' ')} │ ${Texto.slice(i - maxsize, i).padEnd(maxsize, ' ')} │ ${' '.padStart(SIZE(20), ' ')} │`;
                }
            }
            // Adiciona o restante do texto, se houver
            if (Texto.length % maxsize !== 0) {
                text += `\n│ ${' '.padStart(SIZE(10), ' ')} │ ${Texto.slice(Texto.length - (Texto.length % maxsize)).padEnd(maxsize, ' ')} │ ${' '.padStart(SIZE(20), ' ')} │`;
            }
        } else {
            text = Texto;
        }
        return text;
    };

    const cabecalho = 
`${' '.padEnd(SIZE(45), ' ')}${`**** FROTA DELIVERY ****`}

${`Cliente: ${Pedido.NOME_CLIENTE}`.padStart((_margem), ' ')}
${`Celular: ${Pedido.CLIENTE.CELULAR}`.padStart((_margem), ' ')}
${Pedido.ENTREGA && Pedido.ENTREGA.CD_ENTREGA !== undefined ? `Endereço: ${getEnderecoString(Pedido.ENTREGA.ENDERECO)}` : Pedido.NR_MESA ? `Mesa: ${Pedido.NR_MESA}` : `Sem entrega`.padStart((_margem), ' ')}
`

    const itens = 
`
${`${setDate(new Date(Pedido.DT_ABERTURA!), {day: "2-digit", month: "2-digit", year: "numeric", hour: "2-digit", minute: "2-digit", second: "2-digit"}, false, false)}`}
${`Pedido: #${Pedido.CD_PEDIDO}`}
┌─${'─'.padEnd(SIZE(10), '─')}───${'─'.padEnd(SIZE(70), '─')}───${'─'.padEnd(SIZE(20), '─')}─┐
│ ${' '.padEnd(SIZE(40), ' ')}   ${'ITENS'.padEnd(SIZE(20), ' ')}   ${' '.padEnd(SIZE(40), ' ')} │
├─${'─'.padEnd(SIZE(10), '─')}─┬─${'─'.padEnd(SIZE(70), '─')}─┬─${'─'.padEnd(SIZE(20), '─')}─┤
${
Pedido.ITENS
? Pedido.ITENS.map((item, index) => {
    const complementos = pedidoUtil.agruparComplementosPorComposição([item]); 
    return (
`${index !== 0 ? '\n' : ''}│ ${String(item.QTD_PRODUTO).padStart(SIZE(10), ' ')} │ ${String(item.NOME).padEnd(SIZE(70), ' ')} │ ${setMaskMoney((item.VLRUN_PRODUTO*item.QTD_PRODUTO), 'R$', ',').padStart(SIZE(20), ' ')} │`
+ `${item.VARIACAO ? BREAK_ITEM(item.VARIACAO?.NOME ?? "", 70) : ''}`
+ `${BREAK_ITEM(item.OBS_PRODUTO ?? "", 70)}`
// COMPLEMENTOS
+ `${complementos.length > 0 ? complementos.map((compl) => `\n│ ${' '.padStart(SIZE(10), ' ')} │ ${`${compl.COMPLEMENTO}:`.padEnd(SIZE(70), ' ')} │ ${' '.padStart(SIZE(20), ' ')} │
${compl.ITENS.map((complItem: IPedidoItem, indexComplItem: number) => `${indexComplItem !== 0 ? '\n' : ''}│ ${' '.padStart(SIZE(10), ' ')} │ ${`(${complItem.QTD_PRODUTO}x) ${complItem.NOME} ${complItem.COMPOSICAO ? '' : setMaskMoney(complItem.VLRUN_PRODUTO, 'R$')}`.padEnd(SIZE(70), ' ')} │ ${' '.padStart(SIZE(20), ' ')} │`).join('')}`).join('') : ''}`
    )
}).join('')
: ''
}
├─${'─'.padEnd(SIZE(10), '─')}─┴─${'─'.padEnd(SIZE(70), '─')}─┴─${'─'.padEnd(SIZE(20), '─')}─┤
│ ${'*'.padStart(SIZE(10), ' ')}   ${'SUBTOTAL'.padEnd(SIZE(70), '.')}...${setMaskMoney(Number(Pedido.TOTAL ?? 0) - Number(Pedido.ENTREGA?.TAXA ?? 0), 'R$', ',').padStart(SIZE(20), '.')} │
│ ${'*'.padStart(SIZE(10), ' ')}   ${'ENTREGA'.padEnd(SIZE(70), '.')}...${setMaskMoney(Pedido.ENTREGA?.TAXA ?? 0, 'R$', ',').padStart(SIZE(20), '.')} │
│ ${'*'.padStart(SIZE(10), ' ')}   ${'TOTAL'.padEnd(SIZE(70), '.')}...${setMaskMoney(Pedido.TOTAL ?? 0, 'R$', ',').padStart(SIZE(20), '.')} │
├─${'───────────────────────────────────────────────────'.padEnd(SIZE(100), '─')}─┤
│ ${'*'.padStart(SIZE(10), ' ')}   ${`PAGAMENTO: ${Pedido.FORMA_PAGTO ?? '-'}`.padEnd(SIZE(70), '.')}...${setMaskMoney(Number(Pedido.TOTAL ?? 0) + Number(Pedido.VLR_TROCO ?? 0), 'R$', ',').padStart(SIZE(20), '.')} │
│ ${'*'.padStart(SIZE(10), ' ')}   ${'TROCO'.padEnd(SIZE(70), '.')}...${setMaskMoney(Number(Pedido.VLR_TROCO ?? 0), 'R$', ',').padStart(SIZE(20), '.')} │
└─${'─'.padEnd(SIZE(10), '─')}───${'─'.padEnd(SIZE(70), '─')}───${'─'.padEnd(SIZE(20), '─')}─┘
`
    return {
        cabecalho,
        itens
    }
}

export default ImpressaoPedido;