import { IOtherQuery } from "./genericInterfaces";

import * as Yup from 'yup';
import { NOME, CD_CATEGORIA, CD_CATEGORIA_MESTRE } from './defaultModels';

export interface ICardapio {
    CD_CATEGORIA?  : number | null;
    CD_EMPRESA?     : number;
    TIPO            : ECategoriaTipo;
    NOME            : string;
    PRODUTOS?       : IProdutos;
    ATIVO           : boolean;
    CD_CATEGORIA_MESTRE? : number | null;
    MAX?: number;
    MIN?: number;
    ORDEM?: number; 

    COMPOSICAO?: ICardapios;
    INGREDIENTES?: IProdutos;
};
export interface ICardapios extends Array<ICardapio>{};

export enum ECategoriaTipo {
    Cardapio = 'CARDAPIO',
    Composicao = 'COMPOSICAO'
}

export const schemaCadCategoria = Yup.object().shape({
    NOME
});

export const schemaAltCategoria = Yup.object().shape({
    NOME
});

export const schemaCadComposicao = Yup.object().shape({
    CD_CATEGORIA_MESTRE,
    NOME
});

export const schemaAltComposicao = Yup.object().shape({
    CD_CATEGORIA_MESTRE,
    NOME
});

export interface IProduto {
    CD_PRODUTO?         : number | null;
    CD_EMPRESA?         : number;
    TIPO                : EProdutoTipo;
    NOME                : string;
    VALOR               : number;
    CD_CATEGORIA?       : number;
    ATIVO?              : boolean;
    DISPONIVEL?         : boolean;
    OBSERVACAO?         : string;
    COMPOSICAO?         : any;

    CATEGORIA?          : any;
    CD_COMPOSICAO?      : number;
    INGREDIENTES        : IProdutoComposicoes | [];
    MAX?                : number;
    MIN?                : number;
    CAMINHO_IMAGEM?     : string;
    IMAGEM?             : File | null;
    LOCAL_IMAGEM?       : string | null;
    MOVIMENTA_ESTOQUE?  : boolean;
    ESTOQUE?            : number;
    CAIXA_NOME?         : string;
    CAIXA_QTD?          : number;
    VARIACAO?           : IProdutoVariacoes;

    OtherQuery?         : IOtherQuery;
};
export interface IProdutos extends Array<IProduto>{};

export interface IProdutoComposicao {
    CD_PRODUTO_COMPOSICAO?  : number | null;
    CD_PRODUTO              : number;
    CD_INGREDIENTE          : number;
    QTD_INGREDIENTE         : number;

    NOME_PRODUTO?            : string | null;
    INGREDIENTE?             : string | null;
    QUANTIDADE?              : number | null;
}
export interface IProdutoComposicoes extends Array<IProdutoComposicao>{};

export interface IProdutoVariacao {
    CD_PRODUTO_VARIACAO?    : number;
    CD_PROODUTO?            : number;
    NOME                    : string;
    VALOR                   : number;
}
export interface IProdutoVariacoes extends Array<IProdutoVariacao>{};

export enum EProdutoTipo {
    Produto = 'PRODUTO',
    Ingrediente = 'INGREDIENTE'
}

export enum ETpFiltroProduto {
    Nome     = 'NOME',
    Tipo     = 'TIPO',
    Categoria = 'CATEGORIA.NOME',
    Composicao = 'COMPOSICAO.NOME'
};

export const schemaCadProduto = Yup.object().shape({
    CD_CATEGORIA,
    NOME
});

export const schemaAltProduto = Yup.object().shape({
    CD_CATEGORIA,
    NOME
});

export interface IEstoqueMovimento {
    CD_MOVIMENTO?   : number;
    CD_EMPRESA      : number;
    CD_PRODUTO      : number;
    DT_MOVIMENTO    : string | number | Date;
    MOVIMENTO       : string;
    QUANTIDADE      : number | string;
    CD_PEDIDO?      : number;
}
export interface IEstoqueMovimentos extends Array<IEstoqueMovimento>{};