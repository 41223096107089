import { useState, useMemo, useContext, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
import { DeepPartial, EMode, IReactTable_Grid } from "../../Models/genericInterfaces";
import { CtxAppParams } from "../../Contexts/ctxAppParams";
import useCardapioController, { IUseCardapioController } from "./controller";
import { ICardapio, ICardapios, IProduto } from "../../Models/cardapio";

const useCardapio = (CodigoEmpresa?: string, CodigoItem?: string, NumeroMesa?: string, SalvarDados: boolean = true, Garcom: boolean = false) : IUseCardapio => {
    if (CodigoEmpresa) {
        sessionStorage.setItem('EMPRESA', CodigoEmpresa);
    };
    
    const navigate = useNavigate();
    const { setMesa } = useContext(CtxAppParams);
    
    const { Filtros } = useContext(CtxAppParams);
    const Controller = useCardapioController('listaCardapio', true, String(CodigoEmpresa));
    const [Mode, setMode] = useState<EMode>(EMode.Browse);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selecionado, setSelecionado] = useState<ICardapio>({} as ICardapio); //eslint-disable-line

    const SalvaDados = SalvarDados ?? false;
    const isGarcom = Garcom;
    const NrMesa = NumeroMesa;

    useEffect(() => {
        setMesa((prev) => ({...prev, Numero: NrMesa ?? ""}));
    }, [NrMesa, setMesa])

    const dsItem = useMemo<ICardapio>(() => 
        Controller.Buscar.isSuccess ? 
            Controller.Buscar.data
        : 
            {} as ICardapio
    , [Controller.Buscar]);

    const dsListaCategoria = useMemo<ICardapios>(() => 
        Controller.ListarCategorias.isSuccess 
            ? Controller.ListarCategorias.data && Controller.ListarCategorias.data.length > 0 
                ? Controller.ListarCategorias.data.filter((item) => 
                    item && item.TIPO === "CARDAPIO"
                )
                : [{} as ICardapio]
            : [{} as ICardapio]
    , [Controller.ListarCategorias]);

    const dsListaComposicao = useMemo<ICardapios>(() => 
        Controller.ListarCategorias.isSuccess 
            ? Controller.ListarCategorias.data && Controller.ListarCategorias.data.length > 0 
                ? Controller.ListarCategorias.data.filter((item) => 
                    item && item.TIPO === "COMPOSICAO"
                )
                : [{} as ICardapio]
            : [{} as ICardapio]
    , [Controller.ListarCategorias]);

    const dsLista = useMemo<ICardapios>(() => 
        Controller.Listar.isSuccess 
            ? Controller.Listar.data && Controller.Listar.data.length > 0 
                ? Controller.Listar.data.reduce((accumulator: ICardapio[], data: ICardapio) => {
                    if (!data || !data.PRODUTOS) {
                        return accumulator;
                    }

                    if (Filtros.Cardapio.tipoPesquisa === 'PRODUTOS.NOME' && data.PRODUTOS !== undefined) {
                        const produtosFiltrados = data.PRODUTOS?.filter((produto) =>
                            produto.NOME.toLowerCase().includes(Filtros.Cardapio.pesquisa.toLowerCase())
                        );

                        if (produtosFiltrados && produtosFiltrados.length > 0) {
                            // Retorna um novo array com a categoria intacta e produtos filtrados
                            accumulator.push({
                                ...data,
                                PRODUTOS: produtosFiltrados,
                            });
                        }
                    } else {
                        // Se não for um filtro de PRODUTOS.NOME, apenas mantenha a categoria no resultado
                        accumulator.push(data);
                    }

                    return accumulator.sort((a, b) => ((a.ORDEM ?? 999) > (b.ORDEM ?? 999)) ? 1: -1);
                }, [])
                : [{} as ICardapio]
            : [{} as ICardapio]
    , [Controller.Listar, Filtros.Cardapio]);

    const gridLista = useMemo<IReactTable_Grid>(() => [
        {
            id: "ATIVO",
            Header: "Situação",
            Cell: (row: any) : any =>
                <Form.Check
                    name="SITUACAO"
                    style={{zoom: '1.1'}}
                    id="SITUACAO"
                    type="switch"
                    checked={row.row.original['ATIVO']}
                    label={row.row.original['ATIVO'] ? 'Ativo' : 'Inativo'}
                    onClick={(e: any) => {
                        e.stopPropagation();
                    }}
                    onChange={(e: any) => {
                        e.stopPropagation();
                        Controller.Alterar.mutate({
                            CD_CATEGORIA: row.row.original["CD_CATEGORIA"],
                            ATIVO: !row.row.original['ATIVO']
                        })
                    }}
                />
        },
        {
            id: "NOME",
            Header: "Categoria",
            accessor: "NOME"
        },        
        // {
        //     id: "BtnExcluir",
        //     Header: "",
        //     Cell: (row: any) : any => 
        //         <div className="w-100 d-flex justify-content-around">
        //         <Button 
        //             variant="danger"
        //             onClick={(e: any) => {
        //                 e.stopPropagation();
        //                 setSelecionado(row.row.original);
        //                 setShowModal(true);
        //             }}
        //         >
        //             <FaTrashAlt />
        //         </Button>
        //         </div>
        // },
    ], [Controller.Alterar]);

    const salvar = useCallback((Cardapio: DeepPartial<ICardapio>) => {
        if (Mode === EMode.Edit) {
            Controller.Alterar.mutate(Cardapio, {onSuccess: () => navigate(`/cardapio/${CodigoEmpresa}/`)});
        } else if (Mode === EMode.Include) {
            Controller.Cadastrar.mutateAsync(Cardapio, {onSuccess: () => navigate(`/cardapio/${CodigoEmpresa}/`)}).then(() => {
                if (Controller.ListarCategorias.isLoadingError) {
                    Controller.ListarCategorias.refetch();
                }
            });
        };
    }, [Controller.Cadastrar, Controller.Alterar, Mode, navigate, CodigoEmpresa, Controller.ListarCategorias]);

    const abrir = useCallback((Cardapio: DeepPartial<ICardapio>) => {
        const Codigo = Cardapio.CD_CATEGORIA || Number(CodigoItem);

        if (Cardapio.CD_CATEGORIA === 0) {
            navigate(`/cardapio/${CodigoEmpresa}`);
            return
        };

        if (Cardapio.CD_CATEGORIA !== undefined) { // Se informado, vai mudar a página
            navigate(`/cardapio/${CodigoEmpresa}/cadastro/${Cardapio.CD_CATEGORIA}/${Cardapio.TIPO??''}`);
            return
        };        

        if (Codigo === -1) {
            setMode(EMode.Include);
            Controller.Buscar.reset();
        } else {
            setMode(EMode.Edit);
            Controller.Buscar.mutate({CD_CATEGORIA: Codigo});
        };
    }, [Controller.Buscar, CodigoItem, navigate, CodigoEmpresa]);

    const excluir = (Cardapio: ICardapio) => {
        Controller.Deletar.mutate({
            CD_CATEGORIA: Cardapio.CD_CATEGORIA
        });
    };

    useEffect(() => {
        if (CodigoItem !== undefined) {
            abrir({})
        } else {
            setMode(EMode.Browse);
        }
    }, [CodigoItem, Mode]); //eslint-disable-line

    const abrirProduto = useCallback((Produto: DeepPartial<IProduto>) => {
        if (Produto.CD_PRODUTO === 0) {
            // navigate(`/cardapio/`);
            return
        };

        if (Produto.CD_PRODUTO !== undefined) { // Se informado, vai mudar a página
            navigate(`/produto/${CodigoEmpresa}/cadastro/${Produto.CD_PRODUTO}/${Produto.TIPO??''}`);
            return
        };        
    }, [navigate, CodigoEmpresa]);

    return {
        Salvar: salvar,
        Abrir: abrir,
        Excluir: excluir,

        Item: dsItem,
        Lista: dsLista,
        ListaCategoria: dsListaCategoria,
        ListaComposicao: dsListaComposicao,
        Grid: gridLista,

        Modo: Mode,
        NrMesa,
        isGarcom: isGarcom,
        SalvaDados: SalvaDados,

        AbrirProduto: abrirProduto,

        ShowModal: showModal,
        setShowModal: setShowModal,
        Selecionado: selecionado,
        Controller: Controller,
    }
}

export interface IUseCardapio {
    Salvar              : (Cardapio: DeepPartial<ICardapio>) => void;
    Abrir               : (Cardapio: DeepPartial<ICardapio>) => void;
    Excluir             : (Cardapio: ICardapio) => void;
    Item                : ICardapio;
    Lista               : ICardapios;
    ListaCategoria      : ICardapios;
    ListaComposicao     : ICardapios;
    Grid                : IReactTable_Grid;
    Modo                : EMode;
    AbrirProduto        : (Produto: DeepPartial<IProduto>) => void;
    ShowModal           : boolean;
    setShowModal        : React.Dispatch<React.SetStateAction<boolean>>;
    Selecionado         : ICardapio;
    Controller          : IUseCardapioController;
    NrMesa              : string | undefined;
    isGarcom            : boolean | undefined;
    SalvaDados          : boolean;
};

export default useCardapio;