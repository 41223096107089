import * as Yup from 'yup';
import { CD_EMPRESA, CELULAR, SENHA } from './defaultModels';

export interface ILogin {
    CD_USUARIO?: number;
    CD_EMPRESA: number;
    CELULAR: string;
    SENHA: string;
    ENTREGADOR?: boolean;
    TAXA?: number;
    TAXA_ENTREGA?: number;
    RECEBE_TAXA?: boolean;
    UF?: string;
    CIDADE?: string;
    token?: string;
}

export const schemaLogin = Yup.object().shape({
    CD_EMPRESA,
    CELULAR,
    SENHA
});

export const schemaConsultaEmpresa = Yup.object().shape({
    CELULAR
});

export interface IConsultaEmpresa {
    CD_EMPRESA: number;
    NOME: string;
}