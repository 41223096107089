import { createContext } from 'react';
import { ICtxParams } from '../Models/ctxParams';

const defaultValue = {
    //CD_USUARIO: JSON.parse(sessionStorage.getItem('params')!).CD_USUARIO ?? null,
    //CD_EMPRESA: JSON.parse(sessionStorage.getItem('params')!).CD_EMPRESA ?? null,
    CD_EMPRESA      : null,
    ENTREGADOR      : true,
    TAXA            : 0,
    TAXA_ENTREGA    : 0,
    RECEBE_TAXA     : true,
    DT_COMPETENCIA  : '',
    UF              : '',
    CIDADE          : '',
    IMPRESSORA      : undefined,
    setIMPRESSORA   : undefined
};

export const CtxParams = createContext<ICtxParams>(defaultValue);