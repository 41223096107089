import { useEffect, useState } from "react";
import ModalConfirmacao from "../../Components/formularios/ModalConfirmacao";
import { ICardapio, IProduto, IProdutoVariacao } from "../../Models/cardapio";
import { IPedidoItem, IPedidoItems } from "../../Models/pedido";
import { Button, Col, Form, Row } from "react-bootstrap";
import { DeepPartial } from "../../Models/genericInterfaces";
import useMask from "../../Hooks/utils/useMask";
import { FaMinus, FaPlus } from "react-icons/fa";
import { IUseCardapio } from "../../Hooks/Cardapio";
import ComposicaoCheck from "../../Components/cardapio/composicaoCheck";

interface Props {
    modalContexto: IProduto|undefined;
    setModalContexto: React.Dispatch<React.SetStateAction<IProduto|undefined>>;
    pedidoItems: IPedidoItems;
    setPedidoItems: React.Dispatch<React.SetStateAction<IPedidoItems>>;
    Cardapio: IUseCardapio;
}

const CardapioExibeItem = ({modalContexto, setModalContexto, pedidoItems, setPedidoItems, Cardapio}: Props) => {
    const formatter = useMask();
    const [tmpPedidoItem, setTmpPedidoItem] = useState<DeepPartial<IPedidoItem>>({COMPLEMENTOS: []});

    useEffect(() => {
        if (modalContexto === undefined) {
            setTmpPedidoItem({} as IPedidoItem);
        } else {
            if (modalContexto.DISPONIVEL === false) {
                alert('Produto temporariamente indisponível.')
                setModalContexto(undefined);
            } else if (modalContexto.CD_CATEGORIA !== undefined) { //comparar também se a categoria já carregada é diferente
                Cardapio.Controller.Buscar.mutate({CD_CATEGORIA: modalContexto.CD_CATEGORIA});
            };
        }
    }, [modalContexto, setModalContexto]); //eslint-disable-line

    useEffect(() => { // Carrega item clicado
        setTmpPedidoItem({
            CD_PRODUTO: Number(modalContexto?.CD_PRODUTO),
            OFF_NOME: String(modalContexto?.NOME),
            VLRUN_PRODUTO: modalContexto?.VARIACAO && modalContexto?.VARIACAO.length > 0 ? 0 : Number(modalContexto?.VALOR),
            OFF_VLRUN: modalContexto?.VARIACAO && modalContexto?.VARIACAO.length > 0 ? 0 : Number(modalContexto?.VALOR),
            QTD_PRODUTO: 1,
            COMPLEMENTOS: [],
            CD_PRODUTO_VARIACAO: undefined
        })
    }, [modalContexto]);

    const processaQuantidade = (adicionar: boolean, quantidade: number) => {
        if (adicionar) {
            setTmpPedidoItem({
                ...tmpPedidoItem,
                QTD_PRODUTO: 
                    (modalContexto?.MOVIMENTA_ESTOQUE && ((tmpPedidoItem?.QTD_PRODUTO ?? 1) >= Number(modalContexto?.ESTOQUE)))
                        ? Number(modalContexto?.ESTOQUE)
                        : ((tmpPedidoItem?.QTD_PRODUTO ?? 0) + quantidade)
            })
        } else {
            setTmpPedidoItem({
                ...tmpPedidoItem,
                QTD_PRODUTO: (tmpPedidoItem?.QTD_PRODUTO ?? 1) < 2 ? 1 : ((tmpPedidoItem?.QTD_PRODUTO ?? 0) - quantidade)
            })
        }
    }

    useEffect(() => {
        if (tmpPedidoItem.QTD_PRODUTO === 0) {
            setTmpPedidoItem({
                ...tmpPedidoItem,
                QTD_PRODUTO: 1
            })
        }
    }, [tmpPedidoItem])

    const validaExibeComposicao = (Composicao: ICardapio) => {
        return Composicao.ATIVO && (Composicao.INGREDIENTES && Composicao.INGREDIENTES.length > 0 && Composicao.INGREDIENTES.some(ig => ig.ATIVO))
    };

    return (
        <ModalConfirmacao
                titulo={modalContexto?.NOME}
                show={modalContexto !== undefined} 
                onHide={() => setModalContexto(undefined)}
                variant={"primary"}
                onSubmitHandler={() => {
                    if (tmpPedidoItem.COMPLEMENTOS !== undefined) { // Valida qtd minima das composicoes
                        const qtdPorComplemento: { [key: string]: number } = {};
                    
                        (tmpPedidoItem.COMPLEMENTOS as IPedidoItems).forEach((produto: any) => {
                            const { CD_COMPLEMENTO, QTD_PRODUTO } = produto;
                            if (!qtdPorComplemento[CD_COMPLEMENTO]) {
                                qtdPorComplemento[CD_COMPLEMENTO] = 0;
                            }
                            qtdPorComplemento[CD_COMPLEMENTO] += QTD_PRODUTO;
                        });

                        const pendenciasVariacao = (modalContexto?.VARIACAO && modalContexto?.VARIACAO.length > 0)
                            ? (tmpPedidoItem.CD_PRODUTO_VARIACAO && Number(tmpPedidoItem.CD_PRODUTO_VARIACAO) > 0) ? false : true  //validar se tem variação
                            : false

                        if (pendenciasVariacao) {
                            alert('Selecione uma variação do produto')
                            document.getElementById('variacao'+String(modalContexto?.VARIACAO![0].CD_PRODUTO_VARIACAO))?.focus()
                            return
                        }
                        
                        const pendencias = Cardapio.Item.COMPOSICAO?.find((item) => {
                            if (validaExibeComposicao(item) && (Number(item.MIN) > (qtdPorComplemento[Number(item.CD_CATEGORIA)] ?? 0))) {
                                return item
                            } else {
                                return undefined
                            }
                        })

                        if (pendencias) {
                            alert(`Selecione pelo menos ${pendencias.MIN} item de ${pendencias.NOME}`)
                            return
                        }
                    };

                    setPedidoItems([
                        ...pedidoItems,
                        tmpPedidoItem as IPedidoItem
                    ])
                    setModalContexto(undefined);
                }}
            >
                {
                    modalContexto && modalContexto.CAMINHO_IMAGEM ?
                        <div 
                            className={`mb-3 imgItem`}
                            style={{
                                background: `url(${modalContexto?.CAMINHO_IMAGEM})`,
                                width: '100%',
                                height: '12rem', 
                                borderRadius: '0.5rem', 
                                backgroundSize: '100%',
                                backgroundPosition:'center',
                                backgroundPositionY: '15%'
                            }} 
                        />  
                    :
                        null
                }                    
                {/* Lista Observações */}
                <p style={{fontStyle: 'italic'}}>
                    {
                        modalContexto?.OBSERVACAO ? `Observações: ${modalContexto?.OBSERVACAO}` : 'Nenhuma observação'
                    }  
                </p>

                {/* Lista Ingredientes */}
                <p>
                    {
                        modalContexto?.INGREDIENTES !== undefined && modalContexto?.INGREDIENTES.length > 0 ? `Ingredientes: ${(
                            modalContexto?.INGREDIENTES.map((ingrediente, index) => (
                                index === modalContexto?.INGREDIENTES.length - 1 ? (
                                    `${ingrediente.INGREDIENTE}`
                                ) : (
                                    `${ingrediente.INGREDIENTE}, `
                                )
                            )).join('')
                        )}` : ('')
                    }  
                </p>   
                
                {/* Montagem das variações */}
                {
                    <fieldset onChange={(e) => {
                        const variacaoSelecionada = JSON.parse((e.target as HTMLInputElement).value) as IProdutoVariacao;
                        setTmpPedidoItem((prev) => ({
                            ...prev,
                            VLRUN_PRODUTO: Number(variacaoSelecionada.VALOR) + Number((tmpPedidoItem.COMPLEMENTOS as IPedidoItems).reduce((acc, cur) => acc + cur.VLRUN_PRODUTO, 0)),
                            OFF_VLRUN: Number(variacaoSelecionada.VALOR),
                            OFF_NOME: prev.OFF_NOME + ' ' + variacaoSelecionada.NOME,
                            CD_PRODUTO_VARIACAO: variacaoSelecionada.CD_PRODUTO_VARIACAO
                        }))
                    }}>
                        <Form.Group as={Row} className="mb-1">
                            <Col sm={12}>
                                {
                                    modalContexto?.VARIACAO && modalContexto?.VARIACAO.sort((a, b) => a.VALOR - b.VALOR).map((variacao) => {
                                        return <Form.Check
                                            type="radio"
                                            label={`${variacao.NOME} (${formatter.setMaskMoney(variacao.VALOR, 'R$')})`}
                                            name={'radiosVariacao'}
                                            id={String('variacao'+variacao.CD_PRODUTO_VARIACAO)}
                                            key={String(variacao.CD_PRODUTO_VARIACAO)}
                                            style={{zoom: '1.2'}}
                                            value={JSON.stringify(variacao)}
                                        />
                                    })
                                }
                            </Col>
                        </Form.Group>
                    </fieldset>
                }

                {/* Montagem dos ingredientes */}
                {
                    Cardapio.Item.COMPOSICAO && Cardapio.Item.COMPOSICAO.length > 0 && Cardapio.Item.COMPOSICAO?.map((Composicao) => {
                        // console.log(Composicao)
                        return validaExibeComposicao(Composicao) && <ComposicaoCheck 
                            key={`ComposicaoCheck${Composicao.CD_CATEGORIA}`}
                            Composicao={Composicao} 
                            ProdutoComposicoes={tmpPedidoItem} 
                            setProdutoComposicoes={setTmpPedidoItem} 
                            Adicional={false}
                        />
                    })
                }

                {/* Montagem dos adicionais */}
                {
                    <ComposicaoCheck 
                        key={`AdicionalCheck`}
                        Composicao={Cardapio.Item} 
                        ProdutoComposicoes={tmpPedidoItem} 
                        setProdutoComposicoes={setTmpPedidoItem} 
                        Adicional={true}
                    />
                }
                
                {/* Observações Pedido */}
                <Form.Group className="mt-3"> 
                    <Form.Label>Observações do item:</Form.Label>
                    <Form.Control 
                        name={'OBSERVACAO'}
                        type={'text'}
                        value={tmpPedidoItem?.OBS_PRODUTO ?? ""}
                        onChange={(e) => setTmpPedidoItem({
                            ...tmpPedidoItem,
                            OBS_PRODUTO: e.target.value}
                        )}
                        placeholder={`Exemplo: tirar a cebola...`}
                        size="lg"
                    />
                </Form.Group> 
                <br />
                {/* Quantidade e totalizador do pedido */}
                <Form.Label>Quantidade: {
                    formatter.getQtdCaixaString((tmpPedidoItem.QTD_PRODUTO ?? 1), modalContexto?.CAIXA_QTD, modalContexto?.CAIXA_NOME)
                }</Form.Label>
                <div className="container" style={{ display: 'grid', gridTemplateColumns: '1fr 0.8fr', gridTemplateRows: 'auto', gap: '0.1rem', padding: '0rem 0.5rem' }}>
                    <div className="total d-flex justify-content-between align-items-center" style={{ gridRow: '1', gridColumn: '1/2' }}>
                        <Button variant="primary" onClick={() => processaQuantidade(false, 1)}>
                            <FaMinus />
                        </Button>
                        <Form.Group> 
                            <Form.Control 
                                // className="mx-1"
                                style={{minWidth: '4rem'}}
                                name={'QTD'}
                                type={'number'}
                                min={1}
                                max={modalContexto?.MOVIMENTA_ESTOQUE ? modalContexto?.ESTOQUE : undefined}
                                step={1}
                                onWheel={(e: any) => e.target.blur()}
                                value={tmpPedidoItem?.QTD_PRODUTO ?? "1"}
                                onChange={(e) => setTmpPedidoItem({
                                    ...tmpPedidoItem,
                                    QTD_PRODUTO: Number(e.target.value)}
                                )}
                                size="lg"
                            />
                        </Form.Group> 
                        <Button variant="primary" onClick={() => processaQuantidade(true, 1)}>
                            <FaPlus />
                        </Button>
                    </div>

                    <div className="totalvalue d-flex w-100 justify-items-end align-items-end" style={{ gridRow: '1', gridColumn: '3/3' }}>
                        <h3 style={{color: '#198754', fontWeight: 'bold', minWidth: '8rem', textAlign: 'end'}}>
                            {
                                // modalContexto?.VARIACAO && modalContexto?.VARIACAO.length > 0 
                                // ? formatter.setMaskMoney(((modalContexto?.VARIACAO[0].VALOR ?? 0) * (tmpPedidoItem?.QTD_PRODUTO ?? 1)), 'R$', ',')
                                // : 
                                formatter.setMaskMoney(((tmpPedidoItem?.VLRUN_PRODUTO ?? 0) * (tmpPedidoItem?.QTD_PRODUTO ?? 1)), 'R$', ',')
                            }
                        </h3>
                    </div>
                </div>              
                
                {
                    Number(modalContexto?.CAIXA_QTD ?? 1) > 1 && modalContexto?.CAIXA_NOME ?
                        <Row className="mt-3">
                            <Col xs={5}>
                                <div className="w-100 d-flex align-items-center justify-content-evenly ms-2">
                                    <Button variant="outline-primary" onClick={() => processaQuantidade(false, Number(modalContexto?.CAIXA_QTD ?? 1 * -1))}><FaMinus /> {modalContexto?.CAIXA_NOME}</Button>
                                    <Button variant="outline-primary" onClick={() => 
                                        processaQuantidade(
                                            true, 
                                            Number(tmpPedidoItem.QTD_PRODUTO) < Number(modalContexto?.CAIXA_QTD) 
                                                ? Number(modalContexto?.CAIXA_QTD) - Number(tmpPedidoItem.QTD_PRODUTO) 
                                                : Number(modalContexto?.CAIXA_QTD ?? 1)  
                                        )}><FaPlus /> {modalContexto?.CAIXA_NOME}</Button>
                                </div>
                            </Col>
                            <Col xs={3}></Col>
                            <Col xs={4}></Col>
                        </Row>
                    :
                        <></>
                }
            </ModalConfirmacao>
    )
}

export default CardapioExibeItem;