import * as Yup from 'yup';
import { CD_EMPRESA, NOME, CELULAR, SENHA, SENHA_CONFIRMA, TAXA, ENTREGADOR, TX_ENTREGA } from './defaultModels';

export interface ICadUsuario {
    CD_USUARIO?     : number | null;
    CD_EMPRESA      : number | null;
    NOME            : string | null;
    CELULAR         : string;
    SENHA?          : string;
    SENHA_CONFIRMA? : string;
    TAXA            : number;
    ENTREGADOR      : boolean;
    COD_COR?        : string;
    TX_ENTREGA?     : number;
    RECEBE_TAXA?    : boolean;
    ATIVO?          : boolean;
}
export interface ICadUsuarios extends Array<ICadUsuario>{};

export interface IUsuarioGeolocalizacao {
    CD_USUARIO     : number;
    CD_EMPRESA     : number;
    LATITUDE       : string;
    LONGITUDE      : string;
}

export enum ETpFiltroUsuario {
    Nome     = 'NOME'
}

export const schemaCadUsuario = Yup.object().shape({
    CD_EMPRESA,
    NOME,
    CELULAR,
    SENHA,
    SENHA_CONFIRMA,
    TAXA,
    ENTREGADOR,
    TX_ENTREGA
});

export const schemaAltUsuario = Yup.object().shape({
    CD_EMPRESA,
    NOME,
    CELULAR,
    SENHA_CONFIRMA,
    TAXA,
    ENTREGADOR,
    TX_ENTREGA
});