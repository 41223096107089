import { Accordion, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ICtxParams } from "../../Models/ctxParams";
import { ICtxAppParams } from "../../Contexts/ctxAppParams";
import { FaWrench } from "react-icons/fa";
import { MdAddBusiness } from "react-icons/md";

interface Props {
    AppParams   : ICtxAppParams;
    UserParams  : ICtxParams;
};

export const Gestor = ({AppParams, UserParams}: Props) => {
    const navigate = useNavigate();

    return (
        !UserParams.ENTREGADOR ? 
            <>
                <Accordion className="mt-3">
                    <Accordion.Item eventKey="menuGestao">
                        <Accordion.Header>Gestão</Accordion.Header>
                        <Accordion.Body>
                            <Nav.Link 
                                href="#"
                                onClick={() => navigate("/empresa")} 
                                className="d-flex align-items-center justify-content-start"
                            >
                                <MdAddBusiness style={{fontSize: '1.9rem', width: '3rem'}} />
                                Empresa
                            </Nav.Link>

                            <Nav.Link 
                                href="#"
                                onClick={() => navigate("/suporte")} 
                                className="d-flex align-items-center justify-content-start"
                            >
                                <FaWrench style={{fontSize: '1.5rem', width: '3rem'}} />
                                Suporte
                            </Nav.Link>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </>
        :
            null
    );
};