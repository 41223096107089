import { Button, ButtonGroup, Col, Dropdown, Row } from "react-bootstrap";
import { IUsePedido } from "../../Hooks/Pedido";
import BadgeStatus from "../../Components/formularios/BadgeStatus";
import { EStatus, IEntrega } from "../../Models/cadEntrega";
import { FaCheck, FaTimes } from "react-icons/fa";
import Tabela from "../../Components/tabela";
import { FaPrint } from "react-icons/fa6";
import SelectEntregador from "../../Components/formularios/SelectEntregador";
import { UseQueryResult } from "react-query";
import { ICadUsuarios } from "../../Models/cadUsuario";
import { IUseApp } from "../../Hooks/utils/useApp";
import { TbCashBanknote, TbCashBanknoteOff } from "react-icons/tb";
import { MdOutlineTableBar } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IUseVendaPagamento } from "../../Hooks/VendaPagamento";
import { IVendaPagamentos } from "../../Models/VendaPagamento";
import { IUseReports } from "../../Reports";

interface Props {
    Pedido: IUsePedido;
    formatter: any;
    usuarios: UseQueryResult<ICadUsuarios, unknown>;
    hookApp: IUseApp;
    Pagamento: IUseVendaPagamento;
    Reports: IUseReports;
}

const PedidoListaItem = ({Pedido, formatter, usuarios, hookApp, Pagamento, Reports} : Props) => {
    const navigate = useNavigate();

    return (
        <>
            {/* Dados do Pedido */}
            <Row>
                <Col xs={6}>
                    {`Pedido: #${Pedido.Item.CD_PEDIDO}`}
                </Col>
                <Col xs={6} style={{textAlign: 'end', fontSize: '1.3rem'}}>
                    {`${Pedido.Item.NOME_CLIENTE}`}
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <div className="d-flex justify-content-start">Status: <BadgeStatus icone={false} tipo="pedido" status={Pedido.Item.STATUS} /></div>
                </Col>
                <Col xs={6}>
                    <div className="d-flex justify-content-end">Celular: {formatter.setMaskPhone(Pedido.Item.CLIENTE.CELULAR)}</div>
                </Col>
            </Row>
            <Row>
                <Col xs={6}>
                    <div className="d-flex justify-content-start">
                        {`Aberto em: ${Pedido.Item.DT_ABERTURA ?
                            formatter.setDate(new Date(Pedido.Item.DT_ABERTURA!), {
                                day: "2-digit",
                                weekday: "short",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit"
                            }, false)
                        : '-'}`}
                    </div>
                </Col>
                <Col xs={6}>
                    <div className="d-flex justify-content-end">
                        {`Finalizado em: ${Pedido.Item.DT_FECHAMENTO ?
                            formatter.setDate(new Date(Pedido.Item.DT_FECHAMENTO!), {
                                day: "2-digit",
                                weekday: "short",
                                month: "short",
                                year: "numeric",
                                hour: "2-digit",
                                minute: "2-digit"
                            }, false)
                        : '-'}`}
                    </div>
                </Col>
            </Row>

            {/* Ações */}
            <Row>
                <div className="d-flex my-3 justify-content-around">

                    {
                        Pedido.Item.STATUS === EStatus.Pendente 
                            ? <Button variant="danger" onClick={() => Pedido.Controller.Deletar.mutate({CD_PEDIDO: Pedido.Item.CD_PEDIDO})}><FaTimes /> Cancelar</Button>
                            : <Dropdown as={ButtonGroup}>
                                <Button variant="dark" onClick={() => 
                                    Reports.Print('PEDIDO', Pedido.Item)
                                    // Pedido.Impressao.Imprimir(Pedido.Item, process.env.REACT_APP_AMBIENTE === 'P')
                                }
                                >
                                    <FaPrint /> Imprimir
                                </Button>
                                <Dropdown.Toggle split variant="dark" id="dropdown-impressao" />
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => Reports.Reset()}>Resetar configurações de impressão</Dropdown.Item>
                                    </Dropdown.Menu>
                            </Dropdown>
                    }

                    {
                        Pedido.Item.NR_MESA
                            ? <Button variant="primary" onClick={() => navigate(`/mesa/${Pedido.Item.DT_COMPETENCIA}/cadastro/${Pedido.Item.CD_MESA}`)}><MdOutlineTableBar style={{zoom: '1.3'}} /> {`Mesa ${Pedido.Item.NR_MESA}`}</Button>
                            : Pedido.Item.PAGO
                                ? <Button variant="success" title="clique para excluir a forma de pagamento" onClick={() => {
                                    Pagamento.Modal.Deletar({CD_PAGTO: (Pedido.Item.PAGTO as IVendaPagamentos)[0].CD_PAGTO})
                                }}><TbCashBanknote style={{zoom: '1.4'}} /> Pago</Button>
                                : Pedido.Item.STATUS !== EStatus.Cancelada
                                    ? <Button variant="outline-success" onClick={() => {
                                            Pagamento.Modal.Pagamento({CD_PEDIDO: Pedido.Item.CD_PEDIDO, OFF_TOTAL: Pedido.Item.TOTAL, OFF_FORMA: Pedido.Item.FORMA_PAGTO, OFF_TROCO: Pedido.Item.VLR_TROCO})
                                        }
                                    }><TbCashBanknoteOff style={{zoom: '1.4'}} /> Pagamento</Button>
                                    : null
                    }
                    
                    {(() => {
                        switch (Pedido.Item.STATUS) {
                            case EStatus.Andamento:
                                return <Dropdown as={ButtonGroup}>
                                    <Button 
                                        variant="warning" 
                                        onClick={() => 
                                            Pedido.Alterar({
                                                CD_PEDIDO: Pedido.Item.CD_PEDIDO, STATUS: EStatus.Finalizada
                                            })
                                        }>
                                            <FaCheck /> Finalizar Produção
                                    </Button>
                                    <Dropdown.Toggle split variant="warning" id="dropdown-split-basic" />
                                    <Dropdown.Menu>
                                        <Dropdown.Item onClick={() => 
                                            hookApp.Modal({
                                                Visibilidade    : true,
                                                Titulo          : 'Cancelamento de Pedido',
                                                Descricao       : `Tem certeza que deseja cancelar o pedido? Se tiver uma entrega vinculada, a mesma será cancelada. Se houver pagamento, o mesmo será desconfirmado.`,
                                                Anexos			: undefined,
                                                Inputs          : undefined,
                                                Variante        : 'danger',
                                                onSubmit        : () => {
                                                    if (Pedido.Item.PAGO) {
                                                        // Pagamento.Controller.Deletar.mutateAsync({CD_PAGTO: (Pedido.Item.PAGTO as IVendaPagamentos)[0].CD_PAGTO})
                                                        //     .then(() => 
                                                            Pedido.Controller.Deletar.mutateAsync({CD_PEDIDO: Pedido.Item.CD_PEDIDO})
                                                                .then(() => {
                                                                    // Pedido.Controller.Listar.refetch();
                                                                    Pedido.Controller.Buscar.reset();
                                                                })
                                                            // )
                                                    } else {
                                                        Pedido.Alterar({CD_PEDIDO: Pedido.Item.CD_PEDIDO, STATUS: EStatus.Cancelada})
                                                    }
                                                },
                                            })
                                        }>Cancelar Pedido</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            case EStatus.Cancelada:
                                return null;
                            case EStatus.Finalizada:
                                return null;
                            case EStatus.Pendente:
                                return <Button 
                                    variant="primary" 
                                    onClick={() => 
                                        Pedido.Alterar({
                                            CD_PEDIDO: Pedido.Item.CD_PEDIDO, STATUS: EStatus.Andamento
                                        })
                                    }>
                                        <FaCheck /> Aceitar
                                </Button>;
                            default:
                                return null;
                        }
                    })()}

                    
                </div>
            </Row>

            {/* Dados da Entrega */}
            <Row>
                <div className="mb-3">{
                    Pedido.Item.ENTREGA && Pedido.Item.ENTREGA.CD_ENTREGA !== undefined ?
                        <Tabela
                            hideHeader={true}
                            handleClick={Pedido.VisualizarEntrega}
                            dados={[Pedido.Item.ENTREGA]}
                            colunas={[
                                {
                                    id: "Ender",
                                    Header: "Endereco",
                                    Cell: (row: any) : any => {
                                        const entrega = row.row.original as IEntrega;
                                        return <div style={{fontWeight: 'bold'}}>{formatter.getEnderecoString(entrega.ENDERECO)}</div>
                                    }
                                },
                                {
                                    id: "Status",
                                    Header: "Status",
                                    Cell: (row: any) : any => {
                                        const entrega = row.row.original as IEntrega;
                                        return <BadgeStatus tipo="entrega" icone={false} status={entrega.STATUS} />
                                    }
                                },
                                {
                                    id: "Entregador",
                                    Header: "Entregador",
                                    Cell: (row: any) : any => {
                                        const entrega = row.row.original as IEntrega;
                                        return <SelectEntregador
                                            key={String(entrega.CD_ENTREGA)} 
                                            disabled={entrega.STATUS === EStatus.Finalizada || entrega.STATUS === EStatus.Cancelada} 
                                            CdEntrega={entrega.CD_ENTREGA!} 
                                            CdEntregador={entrega.CD_USUARIO!} 
                                            qryListaUsuarios={usuarios}
                                            isSuccess={Pedido.AtualizarEntregaItem}
                                            controller={Pedido.ControllerEntrega}
                                        />
                                    }
                                }
                            ]}
                        />
                    :
                        Pedido.Item.NR_MESA 
                        ? null
                        : <h6 className="my-3 w-100" style={{textAlign: 'center'}}>{'Pedido no estabelecimento (Não foram informados dados da entrega)'}</h6>
                }</div>
            </Row>

            {/* Dados do pedido */}
            <Row>
                <div className="mb-3">
                    <Tabela
                        colunas={Pedido.GridItens}
                        dados={Pedido.Item.ITENS ? Pedido.Item.ITENS : []}
                    />
                </div>
            </Row>

            <Row>
                <Col xs={6}>
                    <div>
                        <Tabela
                            hideHeader
                            colunas={hookApp.Grid}
                            dados={[
                                {
                                    CHAVE: "SubTotal",
                                    VALOR: formatter.setMaskMoney(Number(Pedido.Item.TOTAL ?? 0) - Number(Pedido.Item.ENTREGA?.TAXA ?? 0), "R$")
                                },
                                {
                                    CHAVE: "Entrega",
                                    VALOR: formatter.setMaskMoney(Number(Pedido.Item.ENTREGA?.TAXA ?? 0), "R$")
                                },
                                {
                                    CHAVE: "Total",
                                    VALOR: formatter.setMaskMoney(Number(Pedido.Item.TOTAL ?? 0), "R$")
                                },
                            ]}
                        />
                    </div>
                </Col>
                <Col xs={6}>
                    <div>
                        <Tabela
                            hideHeader
                            colunas={hookApp.Grid}
                            dados={[
                                {
                                    CHAVE: "Forma Pagto",
                                    VALOR: Pedido.Item.FORMA_PAGTO
                                },
                                {
                                    CHAVE: "Troco Calculado",
                                    VALOR: formatter.setMaskMoney(Number(Pedido.Item.VLR_TROCO ?? 0), "R$")
                                },
                                {
                                    CHAVE: "A Receber",
                                    VALOR: formatter.setMaskMoney(Number(Pedido.Item.TOTAL ?? 0) + Number(Pedido.Item.VLR_TROCO ?? 0), "R$")
                                },
                            ]}
                        />
                    </div>
                </Col>                
            </Row>
        </>
    )
}

export default PedidoListaItem