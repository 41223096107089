import { Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ICtxParams } from "../../Models/ctxParams";
import { ICtxAppParams } from "../../Contexts/ctxAppParams";
import { MdDeliveryDining, MdOutlineTableBar } from "react-icons/md";
import { BsQrCode } from "react-icons/bs";
import { FaCashRegister, FaReceipt, FaStar } from "react-icons/fa";

interface Props {
    AppParams   : ICtxAppParams;
    UserParams  : ICtxParams;
};

export const Operacional = ({AppParams, UserParams}: Props) => {
    const navigate = useNavigate();

    return (
        <>
            {
                !UserParams.ENTREGADOR ?
                    <>
                        <Nav.Link
                            href="#"
                            onClick={() => navigate(`/pedido/${UserParams.DT_COMPETENCIA}`)} 
                            className="d-flex align-items-center justify-content-start"
                        >
                            <FaReceipt style={{fontSize: '1.7rem', width: '3rem'}} />    
                            Pedidos
                        </Nav.Link>

                        <Nav.Link
                            href="#"
                            onClick={() => navigate(`/mesa/${UserParams.DT_COMPETENCIA}`)} 
                            className="d-flex align-items-center justify-content-start"
                        >
                            <MdOutlineTableBar style={{fontSize: '2rem', width: '3rem'}} />    
                            Mesas
                        </Nav.Link>
                    </>
                :
                    null
            }
            

            <Nav.Link
                href="#"
                onClick={() => navigate(`/entrega/${UserParams.DT_COMPETENCIA}`)} 
                className="d-flex align-items-center justify-content-start"
            >
                <MdDeliveryDining style={{fontSize: '2.1rem', width: '3rem'}} />    
                Entregas
            </Nav.Link>

            <Nav.Link 
                href="#"
                onClick={() => navigate("/fechamento")} 
                className="d-flex align-items-center justify-content-start"
            >
                {
                    UserParams.ENTREGADOR
                        ? <FaStar style={{fontSize: '1.6rem', width: '2.9rem'}} /> 
                        : <FaCashRegister style={{fontSize: '1.5rem', width: '3rem'}} />
                }                
                {UserParams.ENTREGADOR ? 'Ranking' : 'Fechamento'}
            </Nav.Link>

            <Nav.Link 
                href="#"
                onClick={() => navigate("/listaPagtoQr")} 
                className="d-flex align-items-center justify-content-start"
            >
                <BsQrCode style={{fontSize: '1.8rem', width: '3rem'}} />  
                Pagamentos QR Code
            </Nav.Link>

            <h1><hr /></h1>
        </>
    );
};