import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { IMesa, IMesaPrincipal, IMesaPrincipals } from "../../Models/mesa";
import useMesaController, { IUseMesaController } from "./controller";
import { DeepPartial } from "../../Models/genericInterfaces";
import { useLocation, useNavigate } from "react-router-dom";
import { EStatus } from "../../Models/cadEntrega";

const useMesa = (DtCompetencia: string = '', CdMesa?: string) : IUseMesa => {
    const DataValida = moment(DtCompetencia, "YYYY-MM-DD", true).isValid();
    const Controller = useMesaController('listaMesas', true, DataValida, DtCompetencia);

    const DataCompetencia = DtCompetencia;

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!DataValida && (location.pathname === `/mesa/${DtCompetencia}`)) {
            navigate('/');
        };
    }, [DataValida, navigate, location.pathname, DtCompetencia])

    const dsLista = useMemo<IMesaPrincipals>(() => {
        const RetornouComSucesso = Controller.Listar.isSuccess && Controller.Listar.data && Controller.Listar.data.length > 0;
        return (
            RetornouComSucesso ?
                Controller.Listar.data!.filter((data: IMesaPrincipal) => {
                    return data
                }).map((data) => {
                    return {
                        ...data, 
                        OFF_REACT_TABLE_PROPS: {
                            alert: data.STATUS === EStatus.Pendente
                        }
                    } as IMesaPrincipal
                })
            :
                [{} as IMesaPrincipal]
        )
    }, [Controller.Listar]);

    const dsItem = useMemo<IMesa>(() => {
        if (Controller.Buscar.isSuccess) {
            const itemEncontrado = dsLista && dsLista.find((item: IMesaPrincipal) => 
                item.HISTORICO.find((mesa: IMesa) => mesa.CD_MESA === Controller.Buscar.data?.CD_MESA)
            );

            const historicoEncontrado = itemEncontrado?.HISTORICO.find((mesa: IMesa) => mesa.CD_MESA === Controller.Buscar.data?.CD_MESA);

            if (historicoEncontrado) {
                return {
                    ...Controller.Buscar.data,
                    STATUS: historicoEncontrado.STATUS,
                    PAGO: historicoEncontrado.PAGO
                }
            } else {
                return Controller.Buscar.data
            }
        }

        return {} as IMesa
    }, [Controller.Buscar, dsLista]);

    const Buscar = useCallback((Mesa: DeepPartial<IMesa>) => {
        const Codigo = Mesa.CD_MESA || Number(CdMesa);

        if (Codigo === -1 || Codigo === undefined ) {
            Controller.Buscar.reset();
        }

        if (dsItem.NR_MESA !== Codigo) {
            Controller.Buscar.mutate({CD_MESA: Codigo});
        }
    }, [Controller.Buscar, dsItem.NR_MESA, CdMesa]);

    const Abrir = useCallback((Mesa: DeepPartial<IMesa>) => {
        Buscar(Mesa);
        navigate(`cadastro/${Mesa.CD_MESA}`)
    }, [navigate, Buscar]);

    useEffect(() => {
        if (CdMesa !== undefined) {
            // console.log(NrMesa)
            Buscar({CD_MESA: Number(CdMesa!)})
        }
    }, [CdMesa]); //eslint-disable-line

    return {
        Controller      : Controller,
        Listar          : dsLista,
        Item            : dsItem,
        Buscar          : Buscar,
        Abrir           : Abrir,
        DataCompetencia : DataCompetencia,
    }
}

export interface IUseMesa {
    Controller      : IUseMesaController;
    Listar          : IMesaPrincipals;
    Item            : IMesa;
    Buscar          : (Mesa: DeepPartial<IMesa>) => void;
    Abrir           : (Mesa: DeepPartial<IMesa>) => void;
    DataCompetencia : string;
}

export default useMesa;