import { Form, InputGroup } from "react-bootstrap";
import Tabela from "../../../Components/tabela";
import { IUsePedidoOffline } from "../../../Hooks/offline/PedidoOffline";
import BadgeValidacao from "../../../Components/formularios/BadgeValidacao";
import { every } from "lodash";
import { IPedido } from "../../../Models/pedido";
import useVendaPagamento from "../../../Hooks/VendaPagamento";
import { v4 as uuidv4 } from "uuid";

interface Props {
    pedido: IUsePedidoOffline;
    validacaoErros: any;
    formatter: any;
}

const CarrinhoPagamento = ({pedido, validacaoErros, formatter} : Props) => {
    const Pagamentos = useVendaPagamento();
    return (
        <>
            <Tabela 
                dados={[
                    {DADO: 'Subtotal', VALOR: pedido.pedido?.OFF_TOTAL},
                    {DADO: 'Taxa de Entrega', VALOR: pedido.pedidoEntrega?.TAXA},
                    {DADO: 'Total', VALOR: (Number(pedido.pedido?.OFF_TOTAL ?? 0) + Number(pedido.pedidoEntrega?.TAXA ?? 0))}
                ]} 
                colunas={pedido.gridTotais}
                hideHeader
            />

            <Form.Group className="mt-3 mb-3">
                <Form.Label>Forma de pagamento:</Form.Label>
                <BadgeValidacao validacao={validacaoErros.FORMA_PAGTO} confirmado={validacaoErros.FORMA_PAGTO !== null} />
                <Form.Select 
                    name={'FORMA_PAGTO'}
                    value={pedido.pedido?.FORMA_PAGTO ?? ''}
                    onChange={(e) => {
                        pedido.setPedido((prev => ({
                            ...prev as IPedido,
                            FORMA_PAGTO: e.target.value
                        })));
                    }}
                    placeholder="A Vista, Pix, Cartão..." 
                    size="lg"
                    isInvalid={every([validacaoErros.FORMA_PAGTO, validacaoErros.FORMA_PAGTO !== null])}
                >
                    <option value={''}></option>
                    {
                        Pagamentos.Controller.ListarFormas.data && Pagamentos.Controller.ListarFormas.data.map((Forma) => 
                            <option key={uuidv4()} value={Forma.NOME}>{Forma.NOME}</option>
                        )
                    }
                </Form.Select>
            </Form.Group>

            {
                Pagamentos.Controller.ListarFormas.data && (String(pedido.pedido?.FORMA_PAGTO) === String(Pagamentos.Controller.ListarFormas.data[0].NOME)) ?
                    <Form.Group className="mt-3">
                        <Form.Label>Troco para:</Form.Label>
                        <BadgeValidacao validacao={validacaoErros.VLR_TROCO} confirmado={validacaoErros.VLR_TROCO !== null} />
                        <InputGroup>
                            <InputGroup.Text id="coin">R$</InputGroup.Text>
                            <Form.Control 
                                autoComplete="off"
                                name={'TROCO'}
                                type={'number'}
                                step={'0.05'}
                                onBlur={() => {
                                    pedido.setPedido((prev) => ({
                                        ...prev as IPedido, 
                                        VLR_TROCO: formatter.setMaskMoney(pedido.pedido?.VLR_TROCO ?? 0, "", ".")
                                    })) 
                                }}
                                onWheel={(e: any) => e.target.blur()}
                                value={pedido.pedido?.VLR_TROCO}
                                onChange={ (e) => {
                                    pedido.setPedido((prev) => ({
                                        ...prev as IPedido, 
                                        VLR_TROCO: Number(e.target.value)
                                    })) 
                                }}
                                isInvalid={every([validacaoErros.VLR_TROCO, validacaoErros.VLR_TROCO !== null])} 
                                size="lg"
                            />
                        </InputGroup>
                    </Form.Group>
                : null
            }
        </>
    )
}

export default CarrinhoPagamento;